/* ------------------------------------------------
  page
---------------------------------------------- */
.hd {
  width: 100%;
  height: 240px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  margin-top:70px;
  @include mq(tab) {
    margin-top: 50px;
    height: 180px;
  }
  @include mq(sp) {
    height: 100px;
  }
  &--topics {
    background-image: url("../img/page/hd_topics.png");
  }
  &__ttl {
    font-size: 24px;
    color: #fff;
    letter-spacing: 0.05em;
    line-height: 240px;
    @include mq(tab) {
      line-height: 180px;
    }
    @include mq(sp) {
      line-height: 100px;
      font-size: 18px;
    }
  }
}

/*topics*/
.topics-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 60px;
  margin-left: -30px;
  @include mq(pc) {
    justify-content: space-around;
    margin-left: 0;
  }
  @include mq(sp) {
    display: block;
  }
  &__item {
    max-width: 340px;
    margin-bottom: 50px;
    text-align: left;
    margin-left: 30px;
    width: 30%;
    @include mq(pc) {
      margin-left: 0;
      margin-bottom: 40px;
      width: 48%;
      max-width: inherit;
    }
    @include mq(tab) {
      width: 46%;
    }
    @include mq(sp) {
      width: 100%;
    }
  }
  &__link {
    display: block;
    &:hover {
      text-decoration: none;
      .topics-list__ttl {
        text-decoration: underline;
      }
      .topics-list__img {
        opacity: .7;
      }
    }
  }
  &__img {
    margin-bottom: 24px;
    transition: opacity 300ms;
    opacity: 1;
  }
  &__ttl {
    margin-bottom: 20px;
    @include mq(sp) {
      margin-bottom: 5px;
    }
  }
  &__meta {
    font-size: 14px;
    &-date {
      display: inline-block;
      background-image: url("../img/common/ico_date_01.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 17px auto;
      padding-left: 20px;
      vertical-align: middle;
      color: #a6a6a6;
      line-height: 28px;
      margin-right: 5px;
    }
    &-category {
      display: inline-block;
      background: #a6a6a6;
      color: #fff;
      line-height: 28px;
      vertical-align: middle;
      padding: 0 5px;
      a {
        color:#fff;
      }
    }
  }
  &--top {
    .topics-list__item {
      max-width: 250px;
      width: 22%;
      margin-bottom: 20px;
      @include mq(pc) {
        margin-left: 0;
        margin-bottom: 40px;
        width: 48%;
        max-width: inherit;
      }
      @include mq(sp) {
        width: 100%;
      }
    }
    .topics-list__ttl {
      margin-bottom: 5px;
    }
    .topics-list__img {
      margin-bottom: 10px;
    }
  }
}
.topics {
  &__img {
    margin-bottom: 50px;
    @include mq(tab) {
      margin-bottom: 30px;
    }
  }
  &__meta {
    margin-bottom: 40px;
    font-size: 14px;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 12px;
    }
    &-date {
      display: inline-block;
      background-image: url("../img/common/ico_date_01.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 17px auto;
      padding-left: 20px;
      vertical-align: middle;
      color: #a6a6a6;
      line-height: 28px;
      margin-right: 10px;
    }
    &-category {
      display: inline-block;
      background: #a6a6a6;
      color: #fff;
      line-height: 28px;
      vertical-align: middle;
      margin-right: 10px;
      padding: 0 15px;
      a {
        color: #fff;
      }
      @include mq(sp) {
        padding: 0 5px;
      }
    }
    &-author {
      color: #9f9f9f;
    }
  }
  &__txt {
    margin-bottom: 60px;
    line-height: 2.4;
    @include mq(tab) {
      margin-bottom: 40px;
      line-height: 2;
    }
  }
}
.topics-sns {
  margin-bottom: 100px;
  @include mq(tab) {
    margin-bottom: 40px;
  }
  &__item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  .fb_iframe_widget > span {
    vertical-align: baseline !important;
  }
}
.page-nav {
  margin-bottom: 100px;
  @include clearfix;
  @include mq(sp) {
    margin-bottom: 60px;
  }
  &__item{
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-size: 8px auto;
    color: #333;
    box-sizing: border-box;
    @include mq(sp) {
      margin-bottom: 20px;
    }
    &--prev {
      float: left;
      width: 50%;
      padding-left: 30px;
      padding-right: 10px;
      letter-spacing: 0.05em;
      background-image: url("../img/common/ico_prev_02.png");
      background-position: left 6px;
      @include mq(tab) {
        padding-left: 20px;
      }
    }
    &--next {
      float: right;
      width: 50%;
      padding-right: 30px;
      padding-left: 10px;
      letter-spacing: 0.05em;
      background-image: url("../img/common/ico_next_02.png");
      background-position: right 6px;
      @include mq(tab) {
        padding-right: 20px;
      }
    }
    &--back {
      display: block;
      clear: both;
      padding: 14px 0;
      border: 2px solid #9f9f9f;
      text-align: center;
      transition: all 300ms;
      &:hover {
        background: #9f9f9f;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
.pager {
  margin-top: 100px;
  margin-bottom: 200px;
  text-align: center;
  @include mq(tab) {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  @include mq(sp) {
    margin-bottom: 50px;
  }
  &__item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    background-repeat: no-repeat;
    background-size: 10px auto;
    line-height: 55px;
    font-size: 18px;
    &--prev {
      width: auto;
      padding-left: 30px;
      height: 55px;
      letter-spacing: 0.05em;
      background-image: url("../img/common/ico_prev_01.png");
      background-position: left center;
      color: #9f9f9f;
      @include mq(sp) {
        text-indent: -9999px;
        width: 20px;
        padding: 0;
      }
      a {
        &:hover {
          background-color: #fff!important;
          color: #000!important;
        }
      }
    }
    &--next {
      width: auto;
      padding-right: 30px;
      letter-spacing: 0.05em;
      background-image: url("../img/common/ico_next_01.png");
      background-position: right center;
      @include mq(sp) {
        text-indent: -9999px;
        width: 20px;
        padding: 0;
      }
      a {
        &:hover {
          background-color: #fff!important;
          color: #000!important;
        }
      }
    }
    &--current {
      background: #9f9f9f;
      color: #fff;
      width: 55px;
      height: 55px;
    }
  }
  &__item a {
    display: inline-block;
    width: 55px;
    height: 55px;
    line-height: 55px;
    vertical-align: middle;
    font-size: 18px;
    transition: all 300ms;
    color: #9f9f9f;
    background-repeat: no-repeat;
    background-size: 10px auto;
    @include mq(tab) {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      margin: 0 6px;
    }
    @include mq(sp) {
      width: 30px;
      height: 30px;
      font-size: 12px;
      line-height: 30px;
      margin: 0 4px;
    }
    &:hover {
      text-decoration: none;
      background-color: #9f9f9f;
      color: #fff;
    }
  }
}
.bottom-post {
  &__ttl {
    margin-bottom: 60px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    @include mq(tab) {
      margin-bottom: 40px;
      font-size: 20px;
    }
    @include mq(sp) {
      font-size: 18x;
      margin-bottom: 1em;
    }
  }
}

.page {
  &__ttl {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 1em;
    @include mq(tab) {
      font-size: 24px;
    }
    @include mq(sp) {
      font-size: 18px;
    }
  }
  &__sub-ttl {
    font-size: 2em;
    margin-bottom: 2em;
  }
}

#MailMessageIndexForm,
#MailMessageConfirmForm,
#MailMessageSubmitForm {
  table {
    width:100%;
  }
  th{
    width:30%;
    text-align: right;
  }
  tr {
    border-bottom:1px solid #efefef;
  }
  td {
    padding:20px;
  }
}
#mail table {
	margin-left:15px;
	margin-right:15px;
	display:block;
	width:auto;
}
#mail .col-input {
	width:240px!important;
}
#mail .col-description {
	font-size:10px;
}
.submit {
	text-align:center;
	margin-top:20px;
	margin-bottom:20px;
}
#flashMessage {
	padding:10px 20px;
	margin:15px auto!important;
	font-weight:bold;
}
.error-message {
	color:#C30;
	background: url(../img/common/warning.png) no-repeat left center;
	padding-left:24px;
	font-weight: bold;
	font-size: small;
}
#MailMessageIndexForm .required,
#MailMessageConfirmForm .required,
#MailMessageSubmitForm .required{
    color:#FFF;
    font-size:10px;
    background-color: #C30;
    padding:4px 5px;
    font-weight: normal;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    white-space: nowrap;
    margin-left:5px;
    margin-right:5px;
    vertical-align: middle;
}
#MailMessageIndexForm .normal,
#MailMessageConfirmForm .normal{
    color:#FFF;
    font-size:10px;
    background-color: #CCC;
    padding:4px 5px;
    font-weight: normal;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    white-space: nowrap;
    margin-left:5px;
    margin-right:5px;
    vertical-align: middle;
}
.form-error {
	background-color:#FCF5EB!important;
}
.message {
	color:#C30;
	font-weight:bold;
	margin:20px;
}
.error {
	color:#C30;
}
.error em {
	color:#06C;
	font-style:normal;
	font-weight:bold;
}
.submit {
	text-align:center;
}
.contents {
  padding-bottom: 100px;
}

.design-plan {
  tr {
    display: table-row!important;
  }
  th {
    text-align: center!important;
    display: table-cell!important;
    width:25%!important;
  }
  td {
    text-align: center!important;
    display: table-cell!important;
    width:25%!important;
  }
  &__detail td{
    text-align: left!important;
  }

}

.btn-contact {
  top: 24px;
  right: 58px;
  display: block;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #505050;
  border-radius: 5px;
  width: 240px;
  height: 60px;
  margin: 0 auto;
  line-height: 60px;
  transition: all 300ms;
  text-align: center;
  &:hover {
    text-decoration: none;
    background: #505050;
    color: #fff;
    &:before {
      right: 18px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
    }
  }
}

.bge-contents {
  [data-bgb] h2 {
      font-size: 26pt!important;
  }
  [data-bgb] h2:after {
      background: none!important;
      display: none!important;
  }
  [data-bgb] h3 {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: .5em 0;
  }
  .plan-head h3 {
    font-size: 30px;
    background: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-align: center;
  }
  .support-block {
    width:48%;
    border:1px solid #DDD;
  }
  .support-block__btn {
    text-align: center;
  }
  strong {
    color:#D55A57
  }
  .plan-read {
    margin-bottom: 100px;
    @include mq(sp) {
      margin-bottom: 60px;
    }
  }
  .plan-table {
    margin-bottom: 100px;
    @include mq(sp) {
      margin-bottom: 60px;
    }
    th:nth-of-type(1) {
      background-color: #F2AE2A;
      border-color: #F2AE2A;
      .plan-table-detail {
        strong {
          color: #F2AE2A;
        }
      }
    }
    td:nth-of-type(1) {
      .plan-table-body {
        color: #F2AE2A;
      }
    }
    th:nth-of-type(2) {
      background-color: #3EAE85;
      border-color: #3EAE85;
      .plan-table-detail {
        strong {
          color: #3EAE85;
        }
      }
    }
    td:nth-of-type(2) {
      .plan-table-body {
        color: #3EAE85;
      }
    }
    th:nth-of-type(3) {
      background-color: #179DCA;
      border-color: #179DCA;
      .plan-table-detail {
        strong {
          color: #179DCA;
        }
      }
    }
    td:nth-of-type(3) {
      .plan-table-body {
        color: #179DCA;
      }
    }
    th {
      padding:25px 20px;
      @include mq(sp) {
        padding:8px;
      }
      .plan-table-head {
        color:#FFF;
        font-size:24pt;
        margin:20px 0 20px 0;
        @include mq(sp) {
          font-size:8pt;
        }
      }
      .plan-table-body {
        color:#FFF;
        font-size:24pt;
        margin:0;
        line-height: 0.9;
        margin-bottom: 20px;
        @include mq(sp) {
          font-size:10pt;
          line-height: 1.2;
        }
        small {
          font-size:18px;
          @include mq(sp) {
            font-size:8pt;
            line-height: 1.2;
          }
        }
      }
      .plan-table-detail {
        border-radius: 1em;
        background-color: #FFF;
        margin:0;
        height: 110px;
        width:319px;
        margin-left:auto;
        margin-right: auto;
        padding:15px 20px;
        font-weight: normal;
        display: table-cell;
        vertical-align: middle;
        @include mq(sp) {
          font-size:8px;
          padding:8px;
        }
      }
    }
    td {
      padding:15px;
      .plan-table-head {
        color:#666;
      }
      .plan-table-body {
        font-size:26px;
        font-weight: bold;
        @include mq(sp) {
          font-size:12px;
        }
      }
      p {
        margin:0;
      }
    }
    tr:nth-child(even) td {
      background-color: #FFF!important;
    }
  }
  .plan-table-writing {
    .plan-table-head {
      margin-top:20px;
      @include mq(sp) {
        margin:0;
      }
    }
    .plan-table-body {
      font-size:18px!important;
      margin-top:10px;
      margin-bottom: 20px;
      line-height: 1.4em;
      @include mq(sp) {
        font-size:8pt!important;
        margin:0;
      }
    }
  }
  .plan-detail-writing-1 {
    margin-bottom:0;
    .bgt-ckeditor-container {
      padding:80px;
      @include mq(sp) {
        padding:20px;
      }
    }
    h3 {
      padding:0;
      text-align: left!important;
    }
  }
  .plan-detail-writing-2 {
    margin-bottom: 120px;
    @include mq(sp) {
      margin-bottom: 60px;
    }
    .bgt-ckeditor-container {
      padding:80px;
      @include mq(sp) {
        padding:20px;
      }
    }
    h3 {
      padding:0;
      text-align: left!important;
    }
  }
  .plan-detail-body-design-3 {
    margin-bottom:100px;
    @include mq(sp) {
      margin-bottom:80px;
    }
    img {
      margin-bottom:20px!important;
    }
  }
}

#Register {
	#MailMessageConfirmForm, #MailMessageIndexForm, #MailMessageSubmitForm {
		margin-top:100px;
		text-align: center;
		font-size:20px;
	}
	.mv__form-input {
		border:1px solid #CCC;
	}
	.btn-primary.form-submit {
		background-color: #e15a57!important;
		color:#ffe!important;
		display: inline-block;
	}
	a.form-submit {
		line-height: 71px;
	}
	a.btn-primary {
		padding:0;
	}
	.error-message {
		display: inline;
	}
	.submit {
		margin-top:100px;
	}
}
.register-attention {
	color:#e15a57;
	font-weight: bold;
}
#FieldMessageSubject {
	input[type=radio] {
		margin-right:10px;
	}
	label {
		margin-right:10px;
	}
}
