@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

/*clear fix*/
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  30% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes arrow1 {
  0% {
    background-position: left -97px;
  }
  50% {
    background-position: left 0;
  }
  100% {
    background-position: left 0;
  }
}

@keyframes arrow2 {
  0% {
    width: 0;
  }
  50% {
    width: 97px;
  }
  100% {
    width: 97px;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 15%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes hyokkori {
  0% {
    opacity: 1;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hyokkori2 {
  0% {
    opacity: 1;
    transform: translateY(50px);
  }
  20% {
    opacity: 1;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fuwafuwa {
  0% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(0, -10px);
  }
}

/* ------------------------------------------------
 	common
  ---------------------------------------------- */
body {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  -webkit-text-size-adjust: 100%;
  color: #383b4a;
  background: #fff;
  font-size: 16px;
  line-height: 1.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

@media screen and (max-width: 480px) {
  body {
    font-size: 13px;
  }
}

a {
  color: #383b4a;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  a {
    color: black;
  }
}

strong {
  font-weight: bold;
}

small {
  font-size: 12px;
}

@font-face {
  font-family: 'Noto Sans CJK JP Subset';
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans CJK JP"), local("NotoSansCJKjp-Bold"), local("NotoSansJP-Bold"), url("../font/NotoSansCJKjp-Bold.woff2") format("woff2"), url("../font/NotoSansCJKjp-Bold.woff") format("woff"), url("../font/NotoSansCJKjp-Bold.ttf") format("truetype");
}

h2 {
  font-size: 2em;
}

.font-noto {
  font-family: "Noto Sans", "Noto Sans CJK JP", "Noto Sans CJK JP Subset", sans-serif;
}

.font-roboto {
  font-family: 'Roboto Condensed', sans-serif;
}

/* wrapper
-------------------------*/
.wrapper {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-bottom: 55px;
  }
}

.wrapper img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

/* header
-------------------------*/
.header {
  display: flex;
  align-items: center;
  height: 70px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1024px) {
  .header {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 65px;
    min-width: auto;
  }
}

.header__logo {
  display: block;
  margin-left: 25px;
  width: 158px;
  opacity: 1;
  transition: opacity 300ms;
  text-decoration: none;
}

.header__logo:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .header__logo {
    width: 160px;
  }
}

@media screen and (max-width: 768px) {
  .header__logo {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .header__bar {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 90;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
}

.header__menu {
  position: absolute;
  top: 0;
  right: 25px;
}

@media screen and (max-width: 1024px) {
  .header__menu {
    right: 80px;
  }
}

@media screen and (max-width: 768px) {
  .header__menu {
    display: none;
  }
}

.header__menu-txt {
  font-weight: bold;
  line-height: 70px;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .header__menu-txt {
    line-height: 65px;
  }
}

.header__menu-txt a {
  border: 1px solid #505050;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  background: #fff;
}

.header__menu-txt a:hover {
  text-decoration: none;
  background-color: #505050;
  transition: all 300ms;
  color: #fff;
}

.header__menu-txt + .header__menu-txt {
  margin-left: 4px;
}

.header__menu-txt + .header__menu-txt:before {
  margin-right: 16px;
}

.header__menu-txt-2 {
  font-weight: bold;
  line-height: 70px;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .header__menu-txt-2 {
    padding-top: 14px !important;
  }
}

.header__menu-txt-2:before {
  display: none;
}

.header__menu-txt-2 a {
  border: none;
  color: #fff;
  background-color: #e15a57;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
}

.header__menu-txt-2 a:hover {
  text-decoration: none;
  background-color: #505050;
  transition: all 300ms;
  color: #fff;
}

.header__menu-txt-2 + .header__menu-txt {
  margin-left: 4px;
}

.header__menu-txt-2 + .header__menu-txt:before {
  margin-right: 16px;
}

.header__menu-btn {
  display: none;
  position: absolute;
  top: 15px;
  right: 25px;
  width: 40px;
  height: 38px;
  box-sizing: border-box;
}

.header__menu-btn:before {
  position: absolute;
  top: 10px;
  left: 5px;
  box-sizing: border-box;
  width: 30px;
  height: 18px;
  border-top: 2px solid #e15a57;
  border-bottom: 2px solid #e15a57;
  content: "";
  transition: all 300ms;
}

.header__menu-btn:after {
  position: absolute;
  top: 18px;
  left: 5px;
  content: "";
  width: 30px;
  height: 2px;
  background: #e15a57;
  transition: all 300ms;
}

@media screen and (max-width: 1024px) {
  .header__menu-btn {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .header__menu-btn {
    top: 13px;
    right: 20px;
  }
}

.header__menu-btn.is_open:before {
  transform: rotate(45deg);
  border-bottom: none;
  top: 16px;
  left: 0px;
}

.header__menu-btn.is_open:after {
  transform: rotate(-45deg);
  top: 18px;
  left: 6px;
}

/* gnav
-------------------------*/
.gnav {
  margin-left: 20px;
}

@media screen and (max-width: 1024px) {
  .gnav {
    position: absolute;
    margin-left: 0;
    top: -72px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.9);
    transition: all 300ms;
    -webkit-overflow-scrolling: touch;
    z-index: 10;
  }
}

@media screen and (max-width: 768px) {
  .gnav {
    top: -260px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}

.gnav__list {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.gnav__list:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 1024px) {
  .gnav__list {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .gnav__list {
    display: block;
    padding-bottom: 14px;
  }
}

.gnav__item {
  position: relative;
  line-height: 70px;
  padding: 0 10px;
}

@media screen and (max-width: 1176px) {
  .gnav__item {
    padding: 0 8px;
  }
}

@media screen and (max-width: 768px) {
  .gnav__item {
    line-height: 1.5;
    padding: 0;
    border-bottom: 1px solid #ddd;
  }
}

.gnav__item:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 18px;
  transform: translateY(-50%);
  background: #383b4a;
  content: "";
}

@media screen and (max-width: 768px) {
  .gnav__item:before {
    display: none;
  }
}

.gnav__item:first-child:before {
  display: none;
}

.gnav__item--contact {
  margin-right: 5px;
}

@media screen and (max-width: 1176px) {
  .gnav__item--contact {
    margin-right: 0;
    padding: 0 0 0 10px;
  }
}

@media screen and (max-width: 1024px) {
  .gnav__item--contact {
    position: fixed;
    bottom: 0;
    right: 0;
    display: block;
    background: #f4f4f4;
    line-height: 40px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 768px) {
  .gnav__item--contact {
    width: 100%;
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .gnav__item--contact .gnav__link {
    position: relative;
    line-height: 1;
    padding: 15px 40px 15px 30px;
    transition: all 300ms;
  }
}

@media screen and (max-width: 1024px) and (max-width: 768px) {
  .gnav__item--contact .gnav__link {
    padding: 20px 40px 20px 30px;
  }
}

@media screen and (max-width: 1024px) {
  .gnav__item--contact .gnav__link:hover {
    text-decoration: none;
    background: #505050;
    color: #fff;
  }
  .gnav__item--contact .gnav__link:hover:before {
    right: 15px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
  }
}

@media screen and (max-width: 1024px) and (max-width: 768px) {
  .gnav__item--contact .gnav__link:hover:before {
    right: inherit;
  }
}

@media screen and (max-width: 1024px) {
  .gnav__item--contact .gnav__link .gg-chevron-right-o {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid #e15a57;
    border-radius: 100px;
    top: 5px;
    left: 5px;
  }
  .gnav__item--contact .gnav__link .gg-chevron-right-o::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-bottom: 2px solid #e15a57;
    border-right: 2px solid #e15a57;
    transform: rotate(-45deg);
    left: 5px;
    top: 6px;
  }
}

.gnav__link {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  color: #383b4a;
  transition: all 300ms;
}

.gnav__link:after {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 0;
  height: 3px;
  border-radius: 10px;
  background: #e15a57;
  content: "";
  transition: all 300ms;
}

@media screen and (max-width: 768px) {
  .gnav__link:after {
    display: none;
  }
}

.gnav__link:hover {
  text-decoration: none;
}

.gnav__link:hover:after {
  width: 100%;
}

@media screen and (max-width: 1176px) {
  .gnav__link {
    padding: 0 2px;
  }
}

@media screen and (max-width: 1024px) {
  .gnav__link {
    padding: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .gnav__link {
    display: block;
    padding: 14px 0;
    font-size: 15px;
  }
}

.gnav__menu {
  display: none;
}

@media screen and (max-width: 768px) {
  .gnav__menu {
    background: #fff;
    color: #e15a57;
    width: calc(100% - 40px);
    max-width: 300px;
    margin: 0 auto;
    border-radius: 2px;
    padding: 10px 0;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .gnav__sp-wrap {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

.gnav.is_open {
  top: 70px;
}

@media screen and (max-width: 768px) {
  .gnav.is_open {
    top: 65px;
  }
}

/* breadcrumb
-------------------------*/
.breadcrumb {
  margin-bottom: 60px !important;
  padding: 15px 0;
}

@media screen and (max-width: 768px) {
  .breadcrumb {
    margin-top: -30px;
    margin-bottom: 20px;
    padding: 0;
  }
}

.breadcrumb__item {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}

.breadcrumb__item + .breadcrumb__item {
  position: relative;
  margin-left: 10px;
  padding-left: 20px;
  background-image: url("../img/common/ico_breadcrumb_01.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 4.5px auto;
}

@media screen and (max-width: 480px) {
  .breadcrumb__item {
    font-size: 12px;
  }
}

/* contents
-------------------------*/
.contents {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .contents {
    margin-top: 50px;
  }
}

/* footer
-------------------------*/
.footer {
  padding: 80px 0 0;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .footer {
    padding: 40px 0 0;
  }
}

.footer__sns {
  margin-bottom: 10px;
}

.footer__sns li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}

.footer__sns a {
  opacity: 1;
  transition: opacity 300ms;
  text-decoration: none;
}

.footer__sns a:hover {
  opacity: .7;
  text-decoration: none;
}

.footer__logo {
  display: inline-block;
  margin-bottom: 60px;
  opacity: 1;
  transition: opacity 300ms;
  text-decoration: none;
}

.footer__logo:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 480px) {
  .footer__logo {
    margin-bottom: 40px;
  }
}

.footer__bottom {
  position: relative;
  padding: 40px 0;
  background: #f4f4f4;
}

@media screen and (max-width: 480px) {
  .footer__bottom {
    padding: 0;
    background: #fff;
  }
  .footer__bottom .container {
    padding: 0;
  }
}

.footer__bottom-btn {
  position: absolute;
  top: 24px;
  right: 58px;
  display: block;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #505050;
  border-radius: 5px;
  width: 240px;
  height: 60px;
  margin: 0 auto;
  line-height: 60px;
  transition: all 300ms;
}

@media screen and (max-width: 1024px) {
  .footer__bottom-btn {
    position: relative;
    top: 0;
    right: 0;
    margin: 20px auto 0;
    width: 100%;
    max-width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .footer__bottom-btn {
    width: calc(100% - 40px);
    max-width: inherit;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.footer__bottom-btn:hover {
  text-decoration: none;
  background: #505050;
  color: #fff;
}

.footer__bottom-btn:hover:before {
  right: 18px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.footer__bottom-btn:before {
  position: absolute;
  top: 24px;
  right: 22px;
  width: 10px;
  height: 10px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  transform: rotate(45deg);
  content: "";
  transition: all 300ms;
}

@media screen and (max-width: 480px) {
  .footer__link {
    border-top: 1px solid #ddd;
  }
}

.footer__link li {
  display: inline-block;
  font-size: 12px;
  margin: 0 12px;
}

@media screen and (max-width: 480px) {
  .footer__link li {
    display: block;
    font-size: 15px;
    margin: 0;
  }
  .footer__link li a {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
}

.footer__copyright {
  text-align: center;
  padding: 20px 0;
}

@media screen and (max-width: 480px) {
  .footer__copyright {
    font-size: 11px;
    padding: 0 20px 20px;
  }
}

.page-top {
  position: relative;
  background: #9c9c9c;
  padding: 30px 0 10px;
  color: #fff;
  font-size: 11px;
  letter-spacing: 0.1em;
}

.page-top:before {
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -8px;
  width: 12px;
  height: 12px;
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  content: "";
  transform: rotate(45deg);
}

/* ------------------------------------------------
 	common  parts    
  ---------------------------------------------- */
.wrap, .fade {
  display: block;
  text-decoration: none;
}

.wrap:hover, .fade:hover {
  text-decoration: none;
}

.ib {
  display: inline-block;
}

.phoneTxt {
  text-decoration: none;
  cursor: default;
}

.is_pc {
  display: block !important;
}

@media screen and (max-width: 768px) {
  .is_pc {
    display: none !important;
  }
}

.is_hidden_pc {
  display: none;
}

@media screen and (max-width: 768px) {
  .is_hidden_pc {
    display: block;
  }
}

.is_tab {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .is_tab {
    display: block !important;
  }
}

@media screen and (max-width: 480px) {
  .is_tab {
    display: none !important;
  }
}

.is_sp {
  display: none !important;
}

@media screen and (max-width: 480px) {
  .is_sp {
    display: block !important;
  }
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    box-sizing: border-box;
  }
}

.animation {
  opacity: 0;
}

.animation.animated {
  animation-fill-mode: both;
}

.animation--fade-up.animated {
  animation-duration: 1s;
  animation-name: fade-up;
}

.animation--fade-in.animated {
  animation-timing-function: ease-in;
  animation-duration: 500ms;
  animation-name: fade-in;
}

.animation--zoom-in.animated {
  animation-timing-function: ease-in;
  animation-duration: 500ms;
  animation-name: zoom-in;
}

.animation--zoom-in-2.animated {
  animation-timing-function: ease-in;
  animation-duration: 500ms;
  animation-delay: 300ms;
  animation-name: zoom-in;
}

.animation--zoom-in-3.animated {
  animation-timing-function: ease-in;
  animation-duration: 500ms;
  animation-delay: 600ms;
  animation-name: zoom-in;
}

.animation--zoom-in-4.animated {
  animation-timing-function: ease-in;
  animation-duration: 500ms;
  animation-delay: 900ms;
  animation-name: zoom-in;
}

.animation--hyokkori.animated {
  animation-timing-function: ease-in-out;
  animation-duration: 600ms;
  animation-name: hyokkori;
}

.animation--fade-left.animated {
  animation-timing-function: ease-in-out;
  animation-duration: 500ms;
  animation-name: fade-left;
}

/* ------------------------------------------------
  TOP
---------------------------------------------- */
.mv {
  margin-top: 70px;
  padding-top: 20px;
  padding-bottom: 90px;
  background-image: url(../img/top/bg_mv_pc.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  font-family: "Noto Sans", "Noto Sans CJK JP", "Noto Sans CJK JP Subset", sans-serif;
}

@media screen and (max-width: 768px) {
  .mv {
    padding-top: 0px;
    background-image: url(../img/top/bg_mv.png);
  }
}

@media screen and (max-width: 480px) {
  .mv {
    padding-top: 0px;
    background-image: url(../img/top/bg_mv.png);
  }
}

.mv__ttl {
  font-size: 70px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px;
  letter-spacing: 0.05em;
  font-feature-settings: "palt" 1;
  line-height: 1.7;
  white-space: nowrap;
}

@media screen and (max-width: 1024px) {
  .mv__ttl {
    font-size: 60px;
  }
}

@media screen and (max-width: 768px) {
  .mv__ttl {
    font-size: 30px;
    line-height: 1.3;
    margin-bottom: 10px;
  }
}

.mv .container {
  max-width: 1100px;
}

@media screen and (max-width: 768px) {
  .mv .container {
    margin-top: 50px;
  }
}

@media screen and (max-width: 480px) {
  .mv .container {
    margin-top: 30px;
  }
}

.mv__body {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .mv__body {
    display: block;
  }
}

.mv__item--img {
  width: 41.36364%;
  max-width: 455px;
}

@media screen and (max-width: 768px) {
  .mv__item--img {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
}

@media screen and (max-width: 480px) {
  .mv__item--img {
    padding: 0;
  }
}

.mv__item--txt {
  width: 59.45455%;
  max-width: 654px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .mv__item--txt {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
}

@media screen and (max-width: 480px) {
  .mv__item--txt {
    padding: 0;
  }
}

.mv__balloon {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 45px;
  flex-wrap: wrap;
  padding: 25px 25px 25px 150px;
  border-radius: 20px;
  text-align: center;
  background-color: #fff;
}

@media screen and (max-width: 1024px) {
  .mv__balloon {
    padding: 15px 15px 15px 20%;
  }
}

@media screen and (max-width: 768px) {
  .mv__balloon {
    padding: 10px 10px 10px 115px;
  }
}

@media screen and (max-width: 480px) {
  .mv__balloon {
    padding: 10px 10px 15px 100px;
  }
}

.mv__balloon:after {
  position: absolute;
  top: -29px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 30px solid #fff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  content: "";
}

.mv__balloon-img {
  position: absolute;
  top: -28px;
  left: -60px;
  width: 34.83333%;
  max-width: 209px;
}

@media screen and (max-width: 1024px) {
  .mv__balloon-img {
    top: 0;
    width: 35%;
  }
}

@media screen and (max-width: 768px) {
  .mv__balloon-img {
    width: 130px;
    top: -15px;
    left: -10px;
  }
}

@media screen and (max-width: 480px) {
  .mv__balloon-img {
    width: 112px;
  }
}

.mv__balloon-txt {
  font-size: 26px;
  font-weight: bold;
  white-space: nowrap;
  font-feature-settings: "palt" 1;
  line-height: 1.4;
}

@media screen and (max-width: 1024px) {
  .mv__balloon-txt {
    white-space: normal;
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .mv__balloon-txt {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .mv__balloon-txt {
    font-size: 13px;
  }
}

.mv__balloon-txt span {
  font-size: 32px;
}

@media screen and (max-width: 768px) {
  .mv__balloon-txt span {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .mv__balloon-txt span {
    font-size: 16px;
  }
}

.mv__balloon-txt strong {
  font-size: 32px;
  background: linear-gradient(transparent 60%, #ffea00 60%);
}

@media screen and (max-width: 768px) {
  .mv__balloon-txt strong {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .mv__balloon-txt strong {
    font-size: 16px;
  }
}

.mv__balloon-note {
  position: absolute;
  right: 10px;
  bottom: -30px;
  font-size: 14px;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .mv__balloon-note {
    font-size: 10px;
    right: 0;
    bottom: -25px;
  }
}

@media screen and (max-width: 480px) {
  .mv__balloon-note {
    font-size: 8px;
  }
}

.mv__data {
  display: block;
  width: calc(50% - 10px);
  height: 100px;
  margin: 0 5px 15px;
  box-sizing: border-box;
  background-color: #de5e57;
  border-radius: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  color: #fff;
  padding-top: 18px;
}

@media screen and (max-width: 768px) {
  .mv__data {
    height: 70px;
    padding-top: 10px;
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .mv__data {
    height: 60px;
    border-radius: 30px;
    margin: 0 5px 10px;
  }
}

.mv__data-ttl {
  display: block;
  font-size: 21px;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  .mv__data-ttl {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .mv__data-ttl {
    font-size: 13px;
  }
}

.mv__data-num {
  font-size: 36px;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  .mv__data-num {
    font-size: 28px;
  }
}

@media screen and (max-width: 480px) {
  .mv__data-num {
    font-size: 21px;
  }
}

.mv__data-mark {
  display: inline-block;
  font-size: 10px;
  vertical-align: top;
  margin-top: 10px;
  line-height: 1;
}

@media screen and (max-width: 480px) {
  .mv__data-mark {
    margin-top: 5px;
  }
}

.mv__btn {
  position: relative;
  display: block;
  width: 76.4526%;
  max-width: 500px;
  background-color: #ffea00;
  border-radius: 35px;
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.05em;
  border: 5px solid #fff;
  margin: 0 auto 50px 54px;
  padding: 8px 0;
  transition: all 300ms;
}

@media screen and (max-width: 1024px) {
  .mv__btn {
    width: 100%;
    margin: 0 auto 50px auto;
  }
}

@media screen and (max-width: 768px) {
  .mv__btn {
    width: auto;
  }
}

@media screen and (max-width: 480px) {
  .mv__btn {
    font-size: 18px;
  }
}

.mv__btn:after {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -7px;
  width: 15px;
  height: 15px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(45deg);
  transition: all 300ms;
  content: "";
}

@media screen and (max-width: 480px) {
  .mv__btn:after {
    width: 10px;
    height: 10px;
  }
}

.mv__btn:hover {
  text-decoration: none;
  background-color: #505050;
  color: #fff;
}

.mv__btn:hover:after {
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
}

.mv__txt {
  text-align: center;
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  line-height: 2;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-shadow: 1px 2px 10px #333;
}

@media screen and (max-width: 768px) {
  .mv__txt {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 1.5;
  }
}

.mv.is_load .mv__balloon {
  animation: fuwafuwa 800ms ease-in-out alternate infinite;
}

.top__register {
  margin-top: 10px;
}

.top__register a {
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  font-weight: bold;
  font-family: Arial;
}

.top__register .contact {
  width: 350px;
  display: inline-block;
  font-size: 1.8rem;
}

@media screen and (max-width: 480px) {
  .top__register {
    display: block;
  }
}

.top__demo {
  margin-top: 10px;
  display: none;
}

.top__demo a {
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  font-weight: bold;
  font-family: Arial;
}

@media screen and (max-width: 480px) {
  .top__demo {
    display: block;
  }
}

.problem {
  font-family: 'YuGothic', 'Yu Gothic', '游ゴシック', '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  position: relative;
  padding-top: 140px;
  padding-bottom: 80px;
  background-image: url("../img/top/bg_problem_01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .problem {
    padding: 60px 0;
  }
}

@media screen and (max-width: 480px) {
  .problem {
    padding: 40px 0;
    background-position: center center;
  }
}

.problem:before {
  position: absolute;
  bottom: -56px;
  left: 50%;
  width: 33px;
  height: 97px;
  background-image: url("../img/top/ico_arrow_01.png");
  background-repeat: no-repeat;
  background-position: left -97px;
  background-size: 33px auto;
  transform: translateX(-50%);
  content: "";
  z-index: 5;
  animation-name: arrow1;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 480px) {
  .problem:before {
    bottom: -40px;
    background-size: 20px auto;
    width: 20px;
    height: 60px;
  }
}

.problem__ttl {
  margin-bottom: 50px;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.4;
  color: #fff;
  letter-spacing: .2em;
}

@media screen and (max-width: 1024px) {
  .problem__ttl {
    letter-spacing: 0.08em;
  }
}

@media screen and (max-width: 768px) {
  .problem__ttl {
    font-size: 32px;
  }
}

@media screen and (max-width: 480px) {
  .problem__ttl {
    margin-bottom: 30px;
    font-size: 25px;
    letter-spacing: 0.01em;
  }
}

.problem__ttl strong {
  color: #ffff00;
}

.problem__txt {
  margin-bottom: 36px;
  font-size: 18px;
  color: #fff;
  font-weight: normal;
  letter-spacing: .15px;
}

@media screen and (max-width: 768px) {
  .problem__txt {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .problem__txt {
    margin-bottom: 20px;
    font-size: 13px;
  }
}

.problem__sub-ttl {
  margin-top: 60px;
  font-size: 34px;
  font-weight: bold;
  color: #fff;
  letter-spacing: .15em;
}

@media screen and (max-width: 768px) {
  .problem__sub-ttl {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .problem__sub-ttl {
    margin-top: 30px;
    font-size: 20px;
    line-height: 1.4;
  }
}

.start {
  font-family: 'YuGothic', 'Yu Gothic', '游ゴシック', '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  margin-bottom: 50px;
  padding-top: 120px;
  padding-bottom: 330px;
  text-align: center;
  background-image: url("../img/top/bg_start_01.png");
  background-size: auto 240px;
  background-position: left bottom;
  background-repeat: repeat-x;
}

@media screen and (max-width: 768px) {
  .start {
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 40px;
    background: none;
  }
}

@media screen and (max-width: 480px) {
  .start {
    padding-top: 50px;
  }
}

.start__ico {
  margin-bottom: 20px;
}

.start__ico-txt {
  margin-bottom: 5px;
  display: block;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #e15a57;
}

@media screen and (max-width: 480px) {
  .start__ico-txt {
    font-size: 16px;
  }
}

.start__ttl {
  margin-bottom: 20px;
  font-size: 50px;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .start__ttl {
    font-size: 45px;
  }
}

@media screen and (max-width: 768px) {
  .start__ttl {
    font-size: 28px;
  }
}

@media screen and (max-width: 480px) {
  .start__ttl {
    font-size: 24px;
    line-height: 1.4;
  }
}

.start__ttl strong {
  color: #e15a57;
}

.start__txt {
  letter-spacing: .15em;
  color: #3e3e3e;
  font-size: 22px;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .start__txt {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .start__txt {
    font-size: 13px;
  }
}

.start__img {
  margin-top: 40px;
}

/*top-section*/
.top-section {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .top-section {
    padding: 40px 0;
  }
}

.top-section__en {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  padding-bottom: 10px;
  letter-spacing: 0.05em;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .top-section__en {
    margin-bottom: 30px;
    font-size: 16px;
  }
}

.top-section__en:before {
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 34px;
  height: 1px;
  background: #c2c2c2;
  transform: translateX(-50%);
  content: "";
}

.top-section__en-inner {
  display: block;
}

.top-section__ttl {
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 34px;
  color: #e15a57;
  letter-spacing: .2em;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .top-section__ttl {
    margin-bottom: 30px;
    font-size: 26px;
    letter-spacing: .18em;
  }
}

@media screen and (max-width: 480px) {
  .top-section__ttl {
    font-size: 24px;
    line-height: 1.5;
  }
}

.top-section__ttl + .top-section__sub-ttl {
  margin-top: -30px;
}

@media screen and (max-width: 480px) {
  .top-section__ttl + .top-section__sub-ttl {
    margin-top: -10px;
  }
}

.top-section__ttl-inner {
  display: block;
}

.top-section__sub-ttl {
  font-size: 40px;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .top-section__sub-ttl {
    font-size: 36px;
  }
}

@media screen and (max-width: 768px) {
  .top-section__sub-ttl {
    font-size: 30px;
  }
}

@media screen and (max-width: 480px) {
  .top-section__sub-ttl {
    font-size: 18px;
  }
}

.top-section__sub-ttl mark {
  font-size: 64px;
  background: transparent;
}

@media screen and (max-width: 768px) {
  .top-section__sub-ttl mark {
    font-size: 40px;
  }
}

@media screen and (max-width: 480px) {
  .top-section__sub-ttl mark {
    font-size: 24px;
  }
}

.top-section__txt {
  letter-spacing: .15em;
  margin-bottom: 90px;
  color: #3e3e3e;
  font-weight: normal;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .top-section__txt {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .top-section__txt {
    margin-bottom: 40px;
    text-align: left;
    font-size: 13px;
    line-height: 1.8;
  }
}

.top-section__txt strong {
  background: linear-gradient(transparent 60%, rgba(210, 217, 0, 0.26) 40%);
}

.top-section__sub-txt {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: .15em;
}

@media screen and (max-width: 480px) {
  .top-section__sub-txt {
    margin-bottom: 1em;
    font-size: 16px;
  }
}

.top-section__btn {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #505050;
  border-radius: 5px;
  width: 340px;
  height: 60px;
  margin: 0 auto;
  line-height: 60px;
  transition: all 300ms;
}

@media screen and (max-width: 480px) {
  .top-section__btn {
    width: 100%;
    font-size: 16px;
  }
}

.top-section__btn:hover {
  text-decoration: none;
  background: #505050;
  color: #fff;
}

.top-section__btn:hover:before {
  right: 18px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.top-section__btn:before {
  position: absolute;
  top: 24px;
  right: 22px;
  width: 10px;
  height: 10px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  transform: rotate(45deg);
  content: "";
  transition: all 300ms;
}

.top-section--bg {
  background: #f4f4f4;
}

.top-section--support {
  margin-top: 60px;
  padding-top: 128px;
  padding-bottom: 88px;
  background-image: url("../img/top/bg_support_01.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .top-section--support {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}

.top-section--support .top-section__en {
  color: #fff;
}

.top-section--support .top-section__ttl {
  color: #fff;
}

#under-triangle {
  background: url("../img/top/under-triangle.png") center no-repeat;
}

@media screen and (max-width: 480px) {
  #under-triangle {
    background: url("../img/top/under-triangle_sp.png") center no-repeat;
  }
}

#under-triangle p {
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}

@media screen and (max-width: 480px) {
  #under-triangle p {
    margin-bottom: 15px;
  }
}

.strategy-block-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .strategy-block-wrap {
    display: block;
  }
}

.strategy-block {
  position: relative;
  width: 48%;
  max-width: 520px;
  min-height: 400px;
  padding: 45px;
  border-radius: 6px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .strategy-block {
    width: 100%;
    height: auto;
    padding: 30px 20px;
    min-height: inherit;
    max-width: inherit;
  }
}

.strategy-block--01 {
  background: #bbe4d6;
}

.strategy-block--01 .strategy__block-sub-ttl {
  letter-spacing: 0.05em;
}

.strategy-block--02 {
  background: #fae7b8;
}

@media screen and (max-width: 768px) {
  .strategy-block--02 {
    margin-top: 20px;
    padding: 35px 20px 30px;
  }
}

.strategy-block--02:before {
  position: absolute;
  top: 50%;
  left: -70px;
  width: 97px;
  width: 0px;
  height: 34px;
  background-image: url("../img/top/ico_arrow_02.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 97px auto;
  transform: translateY(-50%);
  content: "";
  animation-name: arrow2;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 768px) {
  .strategy-block--02:before {
    background-image: url("../img/top/ico_arrow_01.png");
    background-size: 20px;
    width: 20px;
    height: 60px;
    top: -40px;
    left: 50%;
    transform: translate(-50%, 0);
    animation-name: arrow1;
  }
}

.strategy-block__ttl {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  background: #fff;
  color: #e15a57;
  border-radius: 30px;
  margin-bottom: 40px;
  padding: 10px 58px;
}

@media screen and (max-width: 1024px) {
  .strategy-block__ttl {
    white-space: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .strategy-block__ttl {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .strategy-block__ttl {
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.strategy-block__sub-ttl {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .strategy-block__sub-ttl {
    font-size: 22px;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 480px) {
  .strategy-block__sub-ttl {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.feature-block-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 480px) {
  .feature-block-wrap {
    display: block;
  }
}

.feature-block {
  position: relative;
  width: 33.3%;
  box-sizing: border-box;
  text-align: left;
  padding: 30px;
  border-top: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
}

@media screen and (max-width: 768px) {
  .feature-block {
    width: 50%;
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .feature-block {
    width: 100%;
    border-right: none;
  }
}

.feature-block:before {
  position: absolute;
  top: 0;
  right: -1px;
  width: 1px;
  height: 60px;
  background: #e15a57;
  content: "";
}

@media screen and (max-width: 480px) {
  .feature-block:before {
    top: -1px;
    right: inherit;
    left: 0;
    width: 60px;
    height: 1px;
  }
}

.feature-block:nth-child(3n) {
  border-right: none;
}

@media screen and (max-width: 768px) {
  .feature-block:nth-child(3n) {
    border-right: 1px solid #d0d0d0;
  }
}

@media screen and (max-width: 480px) {
  .feature-block:nth-child(3n) {
    border-right: none;
  }
}

.feature-block:nth-child(3n):before {
  display: none;
}

@media screen and (max-width: 768px) {
  .feature-block:nth-child(3n):before {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .feature-block:nth-child(2n) {
    border-right: none;
  }
  .feature-block:nth-child(2n):before {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .feature-block:nth-child(2n):before {
    display: block;
  }
}

.feature-block__ttl {
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .feature-block__ttl {
    font-size: 16px;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    margin-bottom: 0;
  }
  .feature-block__ttl:after {
    position: absolute;
    top: 19px;
    width: 7px;
    height: 7px;
    margin-left: 1em;
    border-top: 1px solid #383b4a;
    border-right: 1px solid #383b4a;
    -ms-transform: rotate(45deg);
    transform: rotate(135deg);
    content: "";
    transition: all 300ms;
  }
}

@media screen and (max-width: 480px) {
  .feature-block__txt {
    display: none;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.howto-block-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 70px;
  justify-content: space-around;
}

@media screen and (max-width: 480px) {
  .howto-block-wrap {
    display: block;
  }
}

.howto-block {
  position: relative;
  width: 350px;
  height: 350px;
  padding: 95px 10px 50px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
}

@media screen and (max-width: 1024px) {
  .howto-block {
    max-width: 350px;
    width: 31%;
    height: auto;
    padding: 70px 20px 30px;
  }
}

@media screen and (max-width: 480px) {
  .howto-block {
    width: 100%;
    margin: 0 auto 100px;
    padding: 40px 20px 30px;
  }
  .howto-block:last-child {
    margin: 0 auto 20px;
  }
}

.howto-block:before {
  position: absolute;
  top: 50%;
  left: -45px;
  width: 116px;
  height: 58px;
  background-image: url("../img/top/ico_arrow_03.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
  transform: translateY(-50%);
  content: "";
}

@media screen and (max-width: 1024px) {
  .howto-block:before {
    width: 80px;
  }
}

@media screen and (max-width: 768px) {
  .howto-block:before {
    left: -30px;
    width: 40px;
    margin-top: -20px;
  }
}

@media screen and (max-width: 480px) {
  .howto-block:before {
    top: -115px;
    margin-top: 0;
    background-image: url("../img/top/ico_arrow_04.png");
    width: 38px;
    height: 156px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.howto-block__number {
  position: absolute;
  top: -115px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 130px;
  font-weight: lighter;
}

@media screen and (max-width: 1024px) {
  .howto-block__number {
    top: -90px;
    font-size: 100px;
  }
}

@media screen and (max-width: 768px) {
  .howto-block__number {
    top: -70px;
    font-size: 80px;
  }
}

@media screen and (max-width: 480px) {
  .howto-block__number {
    top: -50px;
    font-size: 60px;
  }
}

.howto-block__ico {
  margin-bottom: 10px;
}

.howto-block__ttl {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .howto-block__ttl {
    font-size: 18px;
    line-height: 1.4;
  }
}

@media screen and (max-width: 768px) {
  .howto-block__txt {
    font-size: 13px;
  }
}

.howto-block--01:before {
  display: none;
}

.howto-block--01 .howto-block__number {
  color: #80cdb2;
}

.howto-block--02 .howto-block__number {
  color: #5bbddd;
}

.howto-block--03 .howto-block__number {
  color: #c495df;
}

.merit-block-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 140px;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .merit-block-wrap {
    padding-top: 100px;
  }
}

@media screen and (max-width: 480px) {
  .merit-block-wrap {
    display: block;
    padding-top: 80px;
  }
}

.merit-block {
  position: relative;
  width: 250px;
  height: 360px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
}

@media screen and (max-width: 1024px) {
  .merit-block {
    width: 24%;
    padding-bottom: 30px;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .merit-block {
    width: 48%;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 480px) {
  .merit-block {
    width: 100%;
    margin-bottom: 80px;
  }
  .merit-block:last-child {
    margin-bottom: 20px;
  }
}

.merit-block__number {
  position: absolute;
  top: -105px;
  right: 0;
  font-size: 119px;
  line-height: 1;
  font-weight: lighter;
}

@media screen and (max-width: 1024px) {
  .merit-block__number {
    font-size: 100px;
    top: -90px;
  }
}

@media screen and (max-width: 768px) {
  .merit-block__number {
    font-size: 80px;
    top: -70px;
  }
}

@media screen and (max-width: 480px) {
  .merit-block__number {
    font-size: 60px;
    top: -60px;
  }
}

.merit-block__ttl-wrap {
  display: table;
  width: 100%;
  height: 130px;
  margin-bottom: 35px;
  box-sizing: border-box;
}

@media screen and (max-width: 480px) {
  .merit-block__ttl-wrap {
    height: auto;
    margin-bottom: 20px;
    padding: 20px 0;
  }
}

.merit-block__ttl {
  display: table-cell;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  vertical-align: middle;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .merit-block__ttl {
    font-size: 18px;
  }
}

.merit-block__txt {
  padding: 0 20px;
}

.merit-block--01 .merit-block__ttl-wrap {
  background: #80cdb2;
}

.merit-block--01 .merit-block__number {
  color: #80cdb2;
}

.merit-block--01 .merit-block__txt + .merit-block__txt {
  position: relative;
  margin-top: 40px;
}

.merit-block--01 .merit-block__txt + .merit-block__txt:before {
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -14px;
  width: 14px;
  height: 14px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(45deg);
  content: "";
}

.merit-block--02 .merit-block__ttl-wrap {
  background: #5bbddd;
}

.merit-block--02 .merit-block__number {
  color: #5bbddd;
}

.merit-block--03 .merit-block__ttl-wrap {
  background: #c495df;
}

.merit-block--03 .merit-block__number {
  color: #c495df;
}

.merit-block--04 .merit-block__ttl-wrap {
  background: #face76;
}

.merit-block--04 .merit-block__number {
  color: #face76;
}

.case-block {
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  background: #f4f4f4;
  margin-bottom: 30px;
}

@media screen and (max-width: 480px) {
  .case-block {
    display: block;
  }
  .case-block:last-child {
    margin-bottom: 0;
  }
}

.case-block__txt-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  max-width: 540px;
}

@media screen and (max-width: 768px) {
  .case-block__txt-wrap {
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .case-block__txt-wrap {
    width: 100%;
  }
}

.case-block__txt-inner {
  text-align: left;
  padding: 0 95px;
}

@media screen and (max-width: 1024px) {
  .case-block__txt-inner {
    padding: 30px 20px;
  }
}

.case-block__name {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .case-block__name {
    font-size: 18px;
  }
}

.case-block__txt {
  font-size: 15px;
}

@media screen and (max-width: 480px) {
  .case-block__txt {
    font-size: 13px;
  }
}

.case-block__img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 55%;
}

.case-block__img .bg_img {
  visibility: hidden;
}

@media screen and (max-width: 480px) {
  .case-block__img {
    width: 100%;
  }
}

.case-block:nth-child(even) {
  flex-direction: row-reverse;
}

.plan-display {
  width: 460px;
  margin: 0 auto 60px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .plan-display {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 40px;
  }
}

.plan-display__ttl {
  padding: 21px 0;
  font-size: 22px;
  font-weight: bold;
  background: #e15a57;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .plan-display__ttl {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .plan-display__ttl {
    font-size: 16px;
    padding: 15px 0;
  }
}

.plan-display__txt {
  padding: 12px 0;
  font-size: 36px;
  font-weight: bold;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .plan-display__txt {
    font-size: 30px;
  }
}

@media screen and (max-width: 480px) {
  .plan-display__txt {
    padding: 10px 0;
    font-size: 26px;
  }
}

.plan-display__txt .tax {
  font-size: 20px;
}

.plan-display__func {
  padding: 6px 20%;
  font-size: 20px;
  text-align: left;
}

@media screen and (max-width: 480px) {
  .plan-display__func {
    padding: 6px 10%;
  }
}

.plan-display__func::before {
  content: "✔";
  margin-right: 10px;
  color: #e15a57;
}

.plan-block-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .plan-block-wrap {
    display: block;
  }
}

.plan-block {
  width: 320px;
}

@media screen and (max-width: 1024px) {
  .plan-block {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .plan-block {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 60px;
  }
  .plan-block:last-child {
    margin: 0 auto 20px;
  }
}

.plan-block__ico {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .plan-block__ico {
    width: 100px;
    margin: 0 auto 20px;
  }
}

.plan-block__ttl {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 480px) {
  .plan-block__ttl {
    margin-bottom: 1em;
    font-size: 18px;
  }
}

.plan-block__txt {
  text-align: left;
}

.support-block-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .support-block-wrap {
    display: block;
  }
}

.support-block {
  position: relative;
  width: 340px;
  height: 340px;
  background: #fff;
  border-radius: 5px;
  padding: 35px;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .support-block {
    margin-bottom: 20px;
    width: 48%;
    height: auto;
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 768px) {
  .support-block {
    width: 100%;
    max-width: 520px;
    margin: 0 auto 20px;
    padding: 40px;
  }
}

@media screen and (max-width: 480px) {
  .support-block {
    padding: 20px;
  }
}

.support-block__ttl {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 30px;
}

@media screen and (max-width: 768px) {
  .support-block__ttl {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .support-block__ttl {
    font-size: 20px;
  }
}

.support-block__txt {
  text-align: left;
}

@media screen and (max-width: 768px) {
  .support-block__txt {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
  .support-block__txt {
    margin-bottom: 1em;
  }
}

.support-block__btn {
  display: block;
  position: absolute;
  bottom: 35px;
  left: 50%;
  width: 270px;
  height: 60px;
  line-height: 60px;
  opacity: 1;
  transition: opacity 300ms;
  text-decoration: none;
  border-radius: 30px;
  font-weight: bold;
  color: #fff;
  transform: translateX(-50%);
}

.support-block__btn:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .support-block__btn {
    bottom: 0;
    left: 0;
    position: relative;
    margin: 0 auto;
    transform: translateX(0);
    width: 100%;
  }
}

.support-block__btn:before {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 10px;
  height: 10px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  content: "";
  transition: right 300ms;
}

.support-block__btn:hover {
  text-decoration: none;
}

.support-block__btn:hover:before {
  right: 18px;
}

.support-block--01 .support-block__ttl {
  color: #80cdb2;
}

.support-block--01 .support-block__btn {
  background-color: #80cdb2;
}

.support-block--02 .support-block__ttl {
  color: #5bbddd;
  line-height: 1.2;
  margin-bottom: 10px;
}

.support-block--02 .support-block__btn {
  background-color: #5bbddd;
}

.support-block--03 .support-block__ttl {
  color: #c495df;
  line-height: 1.2;
  margin-bottom: 10px;
}

.support-block--03 .support-block__btn {
  background-color: #c495df;
}

.bottom-section {
  padding: 80px 0;
  background: #e15a57;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .bottom-section {
    padding: 40px 0;
  }
}

.bottom-section__ttl {
  margin-bottom: 34px;
  font-size: 34px;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .bottom-section__ttl {
    font-size: 16px;
  }
}

.bottom-section__btn {
  display: block;
  width: 340px;
  height: 80px;
  margin: 0 auto;
  background: #fff;
  color: #e15a57;
  line-height: 80px;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.05em;
  transition: all 300ms;
  opacity: 1;
  transition: opacity 300ms;
  text-decoration: none;
}

.bottom-section__btn:hover {
  opacity: .7;
  text-decoration: none;
}

.bottom-section__form-input {
  width: 304px;
  height: 61px;
  border: 1px solid #fff;
  box-sizing: border-box;
  padding: 0 25px;
  font-size: 16px;
  vertical-align: middle;
  -webkit-appearance: none;
  border-radius: 0;
  box-shadow: none;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .bottom-section__form-input {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 10px;
    height: 50px;
    padding: 0 20px;
  }
}

.bottom-section__form-btn {
  margin: 0 10px;
  background: #fff;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-align: center;
  border-radius: 5px;
  color: #e15a57;
  border: none;
  width: 304px;
  height: 71px;
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  opacity: 1;
  transition: opacity 300ms;
  text-decoration: none;
}

.bottom-section__form-btn:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .bottom-section__form-btn {
    display: block;
    font-size: 15px;
    height: 50px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}

.bottom-section__form-btn.contact {
  width: 350px;
  display: inline-block;
  font-size: 1.8rem;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  font-weight: bold;
  font-family: Arial;
  transition: all 300ms;
}

@media screen and (max-width: 768px) {
  .bottom-section__form-btn.contact {
    font-size: 18px;
  }
}

.bottom-section__form-btn.contact:hover {
  opacity: 1;
  background: #505050;
  color: #fff;
}

@media screen and (max-width: 480px) {
  #feature {
    padding: 0;
  }
}

/* ------------------------------------------------
  page
---------------------------------------------- */
.hd {
  width: 100%;
  height: 240px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  margin-top: 70px;
}

@media screen and (max-width: 768px) {
  .hd {
    margin-top: 50px;
    height: 180px;
  }
}

@media screen and (max-width: 480px) {
  .hd {
    height: 100px;
  }
}

.hd--topics {
  background-image: url("../img/page/hd_topics.png");
}

.hd__ttl {
  font-size: 24px;
  color: #fff;
  letter-spacing: 0.05em;
  line-height: 240px;
}

@media screen and (max-width: 768px) {
  .hd__ttl {
    line-height: 180px;
  }
}

@media screen and (max-width: 480px) {
  .hd__ttl {
    line-height: 100px;
    font-size: 18px;
  }
}

/*topics*/
.topics-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 60px;
  margin-left: -30px;
}

@media screen and (max-width: 1024px) {
  .topics-list {
    justify-content: space-around;
    margin-left: 0;
  }
}

@media screen and (max-width: 480px) {
  .topics-list {
    display: block;
  }
}

.topics-list__item {
  max-width: 340px;
  margin-bottom: 50px;
  text-align: left;
  margin-left: 30px;
  width: 30%;
}

@media screen and (max-width: 1024px) {
  .topics-list__item {
    margin-left: 0;
    margin-bottom: 40px;
    width: 48%;
    max-width: inherit;
  }
}

@media screen and (max-width: 768px) {
  .topics-list__item {
    width: 46%;
  }
}

@media screen and (max-width: 480px) {
  .topics-list__item {
    width: 100%;
  }
}

.topics-list__link {
  display: block;
}

.topics-list__link:hover {
  text-decoration: none;
}

.topics-list__link:hover .topics-list__ttl {
  text-decoration: underline;
}

.topics-list__link:hover .topics-list__img {
  opacity: .7;
}

.topics-list__img {
  margin-bottom: 24px;
  transition: opacity 300ms;
  opacity: 1;
}

.topics-list__ttl {
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .topics-list__ttl {
    margin-bottom: 5px;
  }
}

.topics-list__meta {
  font-size: 14px;
}

.topics-list__meta-date {
  display: inline-block;
  background-image: url("../img/common/ico_date_01.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 17px auto;
  padding-left: 20px;
  vertical-align: middle;
  color: #a6a6a6;
  line-height: 28px;
  margin-right: 5px;
}

.topics-list__meta-category {
  display: inline-block;
  background: #a6a6a6;
  color: #fff;
  line-height: 28px;
  vertical-align: middle;
  padding: 0 5px;
}

.topics-list__meta-category a {
  color: #fff;
}

.topics-list--top .topics-list__item {
  max-width: 250px;
  width: 22%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .topics-list--top .topics-list__item {
    margin-left: 0;
    margin-bottom: 40px;
    width: 48%;
    max-width: inherit;
  }
}

@media screen and (max-width: 480px) {
  .topics-list--top .topics-list__item {
    width: 100%;
  }
}

.topics-list--top .topics-list__ttl {
  margin-bottom: 5px;
}

.topics-list--top .topics-list__img {
  margin-bottom: 10px;
}

.topics__img {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .topics__img {
    margin-bottom: 30px;
  }
}

.topics__meta {
  margin-bottom: 40px;
  font-size: 14px;
}

@media screen and (max-width: 480px) {
  .topics__meta {
    margin-bottom: 20px;
    font-size: 12px;
  }
}

.topics__meta-date {
  display: inline-block;
  background-image: url("../img/common/ico_date_01.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 17px auto;
  padding-left: 20px;
  vertical-align: middle;
  color: #a6a6a6;
  line-height: 28px;
  margin-right: 10px;
}

.topics__meta-category {
  display: inline-block;
  background: #a6a6a6;
  color: #fff;
  line-height: 28px;
  vertical-align: middle;
  margin-right: 10px;
  padding: 0 15px;
}

.topics__meta-category a {
  color: #fff;
}

@media screen and (max-width: 480px) {
  .topics__meta-category {
    padding: 0 5px;
  }
}

.topics__meta-author {
  color: #9f9f9f;
}

.topics__txt {
  margin-bottom: 60px;
  line-height: 2.4;
}

@media screen and (max-width: 768px) {
  .topics__txt {
    margin-bottom: 40px;
    line-height: 2;
  }
}

.topics-sns {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .topics-sns {
    margin-bottom: 40px;
  }
}

.topics-sns__item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.topics-sns .fb_iframe_widget > span {
  vertical-align: baseline !important;
}

.page-nav {
  margin-bottom: 100px;
}

.page-nav:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 480px) {
  .page-nav {
    margin-bottom: 60px;
  }
}

.page-nav__item {
  margin-bottom: 40px;
  background-repeat: no-repeat;
  background-size: 8px auto;
  color: #333;
  box-sizing: border-box;
}

@media screen and (max-width: 480px) {
  .page-nav__item {
    margin-bottom: 20px;
  }
}

.page-nav__item--prev {
  float: left;
  width: 50%;
  padding-left: 30px;
  padding-right: 10px;
  letter-spacing: 0.05em;
  background-image: url("../img/common/ico_prev_02.png");
  background-position: left 6px;
}

@media screen and (max-width: 768px) {
  .page-nav__item--prev {
    padding-left: 20px;
  }
}

.page-nav__item--next {
  float: right;
  width: 50%;
  padding-right: 30px;
  padding-left: 10px;
  letter-spacing: 0.05em;
  background-image: url("../img/common/ico_next_02.png");
  background-position: right 6px;
}

@media screen and (max-width: 768px) {
  .page-nav__item--next {
    padding-right: 20px;
  }
}

.page-nav__item--back {
  display: block;
  clear: both;
  padding: 14px 0;
  border: 2px solid #9f9f9f;
  text-align: center;
  transition: all 300ms;
}

.page-nav__item--back:hover {
  background: #9f9f9f;
  color: #fff;
  text-decoration: none;
}

.pager {
  margin-top: 100px;
  margin-bottom: 200px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .pager {
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 480px) {
  .pager {
    margin-bottom: 50px;
  }
}

.pager__item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  background-repeat: no-repeat;
  background-size: 10px auto;
  line-height: 55px;
  font-size: 18px;
}

.pager__item--prev {
  width: auto;
  padding-left: 30px;
  height: 55px;
  letter-spacing: 0.05em;
  background-image: url("../img/common/ico_prev_01.png");
  background-position: left center;
  color: #9f9f9f;
}

@media screen and (max-width: 480px) {
  .pager__item--prev {
    text-indent: -9999px;
    width: 20px;
    padding: 0;
  }
}

.pager__item--prev a:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.pager__item--next {
  width: auto;
  padding-right: 30px;
  letter-spacing: 0.05em;
  background-image: url("../img/common/ico_next_01.png");
  background-position: right center;
}

@media screen and (max-width: 480px) {
  .pager__item--next {
    text-indent: -9999px;
    width: 20px;
    padding: 0;
  }
}

.pager__item--next a:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.pager__item--current {
  background: #9f9f9f;
  color: #fff;
  width: 55px;
  height: 55px;
}

.pager__item a {
  display: inline-block;
  width: 55px;
  height: 55px;
  line-height: 55px;
  vertical-align: middle;
  font-size: 18px;
  transition: all 300ms;
  color: #9f9f9f;
  background-repeat: no-repeat;
  background-size: 10px auto;
}

@media screen and (max-width: 768px) {
  .pager__item a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    margin: 0 6px;
  }
}

@media screen and (max-width: 480px) {
  .pager__item a {
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    margin: 0 4px;
  }
}

.pager__item a:hover {
  text-decoration: none;
  background-color: #9f9f9f;
  color: #fff;
}

.bottom-post__ttl {
  margin-bottom: 60px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .bottom-post__ttl {
    margin-bottom: 40px;
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .bottom-post__ttl {
    font-size: 18x;
    margin-bottom: 1em;
  }
}

.page__ttl {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
  .page__ttl {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .page__ttl {
    font-size: 18px;
  }
}

.page__sub-ttl {
  font-size: 2em;
  margin-bottom: 2em;
}

#MailMessageIndexForm table,
#MailMessageConfirmForm table,
#MailMessageSubmitForm table {
  width: 100%;
}

#MailMessageIndexForm th,
#MailMessageConfirmForm th,
#MailMessageSubmitForm th {
  width: 30%;
  text-align: right;
}

#MailMessageIndexForm tr,
#MailMessageConfirmForm tr,
#MailMessageSubmitForm tr {
  border-bottom: 1px solid #efefef;
}

#MailMessageIndexForm td,
#MailMessageConfirmForm td,
#MailMessageSubmitForm td {
  padding: 20px;
}

#mail table {
  margin-left: 15px;
  margin-right: 15px;
  display: block;
  width: auto;
}

#mail .col-input {
  width: 240px !important;
}

#mail .col-description {
  font-size: 10px;
}

.submit {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

#flashMessage {
  padding: 10px 20px;
  margin: 15px auto !important;
  font-weight: bold;
}

.error-message {
  color: #C30;
  background: url(../img/common/warning.png) no-repeat left center;
  padding-left: 24px;
  font-weight: bold;
  font-size: small;
}

#MailMessageIndexForm .required,
#MailMessageConfirmForm .required,
#MailMessageSubmitForm .required {
  color: #FFF;
  font-size: 10px;
  background-color: #C30;
  padding: 4px 5px;
  font-weight: normal;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  white-space: nowrap;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

#MailMessageIndexForm .normal,
#MailMessageConfirmForm .normal {
  color: #FFF;
  font-size: 10px;
  background-color: #CCC;
  padding: 4px 5px;
  font-weight: normal;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  white-space: nowrap;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.form-error {
  background-color: #FCF5EB !important;
}

.message {
  color: #C30;
  font-weight: bold;
  margin: 20px;
}

.error {
  color: #C30;
}

.error em {
  color: #06C;
  font-style: normal;
  font-weight: bold;
}

.submit {
  text-align: center;
}

.contents {
  padding-bottom: 100px;
}

.design-plan tr {
  display: table-row !important;
}

.design-plan th {
  text-align: center !important;
  display: table-cell !important;
  width: 25% !important;
}

.design-plan td {
  text-align: center !important;
  display: table-cell !important;
  width: 25% !important;
}

.design-plan__detail td {
  text-align: left !important;
}

.btn-contact {
  top: 24px;
  right: 58px;
  display: block;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #505050;
  border-radius: 5px;
  width: 240px;
  height: 60px;
  margin: 0 auto;
  line-height: 60px;
  transition: all 300ms;
  text-align: center;
}

.btn-contact:hover {
  text-decoration: none;
  background: #505050;
  color: #fff;
}

.btn-contact:hover:before {
  right: 18px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

.bge-contents [data-bgb] h2 {
  font-size: 26pt !important;
}

.bge-contents [data-bgb] h2:after {
  background: none !important;
  display: none !important;
}

.bge-contents [data-bgb] h3 {
  background: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: .5em 0;
}

.bge-contents .plan-head h3 {
  font-size: 30px;
  background: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
}

.bge-contents .support-block {
  width: 48%;
  border: 1px solid #DDD;
}

.bge-contents .support-block__btn {
  text-align: center;
}

.bge-contents strong {
  color: #D55A57;
}

.bge-contents .plan-read {
  margin-bottom: 100px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-read {
    margin-bottom: 60px;
  }
}

.bge-contents .plan-table {
  margin-bottom: 100px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-table {
    margin-bottom: 60px;
  }
}

.bge-contents .plan-table th:nth-of-type(1) {
  background-color: #F2AE2A;
  border-color: #F2AE2A;
}

.bge-contents .plan-table th:nth-of-type(1) .plan-table-detail strong {
  color: #F2AE2A;
}

.bge-contents .plan-table td:nth-of-type(1) .plan-table-body {
  color: #F2AE2A;
}

.bge-contents .plan-table th:nth-of-type(2) {
  background-color: #3EAE85;
  border-color: #3EAE85;
}

.bge-contents .plan-table th:nth-of-type(2) .plan-table-detail strong {
  color: #3EAE85;
}

.bge-contents .plan-table td:nth-of-type(2) .plan-table-body {
  color: #3EAE85;
}

.bge-contents .plan-table th:nth-of-type(3) {
  background-color: #179DCA;
  border-color: #179DCA;
}

.bge-contents .plan-table th:nth-of-type(3) .plan-table-detail strong {
  color: #179DCA;
}

.bge-contents .plan-table td:nth-of-type(3) .plan-table-body {
  color: #179DCA;
}

.bge-contents .plan-table th {
  padding: 25px 20px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-table th {
    padding: 8px;
  }
}

.bge-contents .plan-table th .plan-table-head {
  color: #FFF;
  font-size: 24pt;
  margin: 20px 0 20px 0;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-table th .plan-table-head {
    font-size: 8pt;
  }
}

.bge-contents .plan-table th .plan-table-body {
  color: #FFF;
  font-size: 24pt;
  margin: 0;
  line-height: 0.9;
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-table th .plan-table-body {
    font-size: 10pt;
    line-height: 1.2;
  }
}

.bge-contents .plan-table th .plan-table-body small {
  font-size: 18px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-table th .plan-table-body small {
    font-size: 8pt;
    line-height: 1.2;
  }
}

.bge-contents .plan-table th .plan-table-detail {
  border-radius: 1em;
  background-color: #FFF;
  margin: 0;
  height: 110px;
  width: 319px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 20px;
  font-weight: normal;
  display: table-cell;
  vertical-align: middle;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-table th .plan-table-detail {
    font-size: 8px;
    padding: 8px;
  }
}

.bge-contents .plan-table td {
  padding: 15px;
}

.bge-contents .plan-table td .plan-table-head {
  color: #666;
}

.bge-contents .plan-table td .plan-table-body {
  font-size: 26px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-table td .plan-table-body {
    font-size: 12px;
  }
}

.bge-contents .plan-table td p {
  margin: 0;
}

.bge-contents .plan-table tr:nth-child(even) td {
  background-color: #FFF !important;
}

.bge-contents .plan-table-writing .plan-table-head {
  margin-top: 20px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-table-writing .plan-table-head {
    margin: 0;
  }
}

.bge-contents .plan-table-writing .plan-table-body {
  font-size: 18px !important;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.4em;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-table-writing .plan-table-body {
    font-size: 8pt !important;
    margin: 0;
  }
}

.bge-contents .plan-detail-writing-1 {
  margin-bottom: 0;
}

.bge-contents .plan-detail-writing-1 .bgt-ckeditor-container {
  padding: 80px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-detail-writing-1 .bgt-ckeditor-container {
    padding: 20px;
  }
}

.bge-contents .plan-detail-writing-1 h3 {
  padding: 0;
  text-align: left !important;
}

.bge-contents .plan-detail-writing-2 {
  margin-bottom: 120px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-detail-writing-2 {
    margin-bottom: 60px;
  }
}

.bge-contents .plan-detail-writing-2 .bgt-ckeditor-container {
  padding: 80px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-detail-writing-2 .bgt-ckeditor-container {
    padding: 20px;
  }
}

.bge-contents .plan-detail-writing-2 h3 {
  padding: 0;
  text-align: left !important;
}

.bge-contents .plan-detail-body-design-3 {
  margin-bottom: 100px;
}

@media screen and (max-width: 480px) {
  .bge-contents .plan-detail-body-design-3 {
    margin-bottom: 80px;
  }
}

.bge-contents .plan-detail-body-design-3 img {
  margin-bottom: 20px !important;
}

#Register #MailMessageConfirmForm, #Register #MailMessageIndexForm, #Register #MailMessageSubmitForm {
  margin-top: 100px;
  text-align: center;
  font-size: 20px;
}

#Register .mv__form-input {
  border: 1px solid #CCC;
}

#Register .btn-primary.form-submit {
  background-color: #e15a57 !important;
  color: #ffe !important;
  display: inline-block;
}

#Register a.form-submit {
  line-height: 71px;
}

#Register a.btn-primary {
  padding: 0;
}

#Register .error-message {
  display: inline;
}

#Register .submit {
  margin-top: 100px;
}

.register-attention {
  color: #e15a57;
  font-weight: bold;
}

#FieldMessageSubject input[type=radio] {
  margin-right: 10px;
}

#FieldMessageSubject label {
  margin-right: 10px;
}
