/* ------------------------------------------------
  TOP
---------------------------------------------- */
.mv {
  margin-top: 70px;
  padding-top: 20px;
  padding-bottom: 90px;
  background-image: url(../img/top/bg_mv_pc.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  font-family: "Noto Sans", "Noto Sans CJK JP", "Noto Sans CJK JP Subset", sans-serif;
  @include mq(tab) {
    padding-top: 0px;
    background-image: url(../img/top/bg_mv.png);
  }
  @include mq(sp) {
    padding-top: 0px;
    background-image: url(../img/top/bg_mv.png);
  }

  &__ttl {
    font-size: 70px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px;
    letter-spacing: 0.05em;
    font-feature-settings: "palt" 1;
    line-height: 1.7;
    white-space: nowrap;
    @include mq(pc) {
      font-size: 60px;
    }
    @include mq(tab) {
      font-size: 30px;
      line-height: 1.3;
      margin-bottom: 10px;
    }
  }

  .container {
    max-width: 1100px;
    @include mq(tab) {
      margin-top: 50px;
    }
    @include mq(sp) {
      margin-top: 30px;
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    @include mq(tab) {
      display: block;
    }
  }

  &__item {

    &--img {
      width: percentage(455px / 1100px);
      max-width: 455px;
      @include mq(tab) {
        width: 100%;
        margin: 0 auto;
        padding: 0 10px;
      }
      @include mq(sp) {
        padding: 0;
      }
    }

    &--txt {
      // padding-left: 54px;
      width: percentage(654px / 1100px);
      max-width: 654px;
      box-sizing: border-box;
      @include mq(tab) {
        width: 100%;
        margin: 0 auto;
        padding: 0 10px;
      }
      @include mq(sp) {
        padding: 0;
      }
    }
  }

  &__balloon {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 45px;
    flex-wrap: wrap;
    padding: 25px 25px 25px 150px;
    border-radius: 20px;
    text-align: center;
    background-color: #fff;
    @include mq(pc) {
      padding: 15px 15px 15px 20%;
    }
    @include mq(tab) {
      padding: 10px 10px 10px 115px;
    }
    @include mq(sp) {
      padding: 10px 10px 15px 100px;
    }

    &:after {
      position: absolute;
      top: -29px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 30px solid #fff;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      content: "";
    }

    &-img {
      position: absolute;
      top: -28px;
      left: -60px;
      width: percentage(209px / 600px);
      max-width: 209px;
      @include mq(pc) {
        top: 0;
        width: 35%;
      }
      @include mq(tab) {
        width: 130px;
        top: -15px;
        left: -10px;
      }
      @include mq(sp) {
        width: 112px;
      }
    }

    &-txt {
      font-size: 26px;
      font-weight: bold;
      white-space: nowrap;
      font-feature-settings: "palt" 1;
      line-height: 1.4;
      @include mq(pc) {
        white-space: normal;
        font-size: 20px;
      }
      @include mq(tab) {
        font-size: 18px;
      }
      @include mq(sp) {
        font-size: 13px;
      }

      span {
        font-size: 32px;
        @include mq(tab) {
          font-size: 24px;
        }
        @include mq(sp) {
          font-size: 16px;
        }
      }

      strong {
        font-size: 32px;
        background: linear-gradient(transparent 60%, #ffea00 60%);
        @include mq(tab) {
          font-size: 24px;
        }
        @include mq(sp) {
          font-size: 16px;
        }
      }
    }

    &-note {
      position: absolute;
      right: 10px;
      bottom: -30px;
      font-size: 14px;
      color: #fff;
      @include mq(pc) {
        font-size: 10px;
        right: 0;
        bottom: -25px;
      }
      @include mq(sp) {
        font-size: 8px;
      }
    }
  }

  &__data {
    display: block;
    width: calc(50% - 10px);
    height: 100px;
    margin: 0 5px 15px;
    box-sizing: border-box;
    background-color: #de5e57;
    border-radius: 50px;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    color: #fff;
    padding-top: 18px;
    @include mq(tab) {
      height: 70px;
      padding-top: 10px;
      font-size: 15px;
    }
    @include mq(sp) {
      height: 60px;
      border-radius: 30px;
      margin: 0 5px 10px;
    }

    &-ttl {
      display: block;
      font-size: 21px;
      line-height: 1.2;
      @include mq(tab) {
        font-size: 18px;
      }
      @include mq(sp) {
        font-size: 13px;
      }
    }

    &-num {
      font-size: 36px;
      line-height: 1.2;
      @include mq(tab) {
        font-size: 28px;
      }
      @include mq(sp) {
        font-size: 21px;
      }
    }

    &-mark {
      display: inline-block;
      font-size: 10px;
      vertical-align: top;
      margin-top: 10px;
      line-height: 1;
      @include mq(sp) {
        margin-top: 5px;
      }
    }
  }

  &__btn {
    position: relative;
    display: block;
    width: percentage(500px / 654px);
    max-width: 500px;
    background-color: #ffea00;
    border-radius: 35px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0.05em;
    border: 5px solid #fff;
    margin: 0 auto 50px 54px;
    padding: 8px 0;
    transition: all 300ms;
    @include mq(pc) {
      width: 100%;
      margin: 0 auto 50px auto;
    }
    @include mq(tab) {
      width: auto;
    }
    @include mq(sp) {
      font-size: 18px;
    }

    &:after {
      position: absolute;
      right: 30px;
      top: 50%;
      margin-top: -7px;
      width: 15px;
      height: 15px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      transform: rotate(45deg);
      transition: all 300ms;
      content: "";
      @include mq(sp) {
        width: 10px;
        height: 10px;
      }
    }

    &:hover {
      text-decoration: none;
      background-color: #505050;
      color: #fff;

      &:after {
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
  }

  &__txt {
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    line-height: 2;
    margin-bottom: 40px;
    padding-top: 40px;
    padding-bottom:40px;
    text-shadow: 1px 2px 10px #333;
    @include mq(tab) {
      font-size: 14px;
      margin-bottom: 15px;
      line-height: 1.5;
    }
  }
}

.mv {
  &.is_load {
    .mv__balloon {
      animation: fuwafuwa 800ms ease-in-out alternate infinite;
    }
  }
}

.top__register {
  margin-top: 10px;

  a {
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;
    font-weight: bold;
    font-family: Arial;
  }

  .contact {
    width: 350px;
    display: inline-block;
    font-size: 1.8rem;
  }

  @include mq(sp) {
    display: block;
  }
}

.top__demo {
  margin-top: 10px;
  display: none;

  a {
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;
    font-weight: bold;
    font-family: Arial;
  }

  @include mq(sp) {
    display: block;
  }
}

.problem {
  font-family: unquote($familyD);
  position: relative;
  padding-top: 140px;
  padding-bottom: 80px;
  background-image: url("../img/top/bg_problem_01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;
  @include mq(tab) {
    padding: 60px 0;
  }
  @include mq(sp) {
    padding: 40px 0;
    background-position: center center;
  }

  &:before {
    position: absolute;
    bottom: -56px;
    left: 50%;
    width: 33px;
    height: 97px;
    background-image: url("../img/top/ico_arrow_01.png");
    background-repeat: no-repeat;
    background-position: left -97px;
    background-size: 33px auto;
    transform: translateX(-50%);
    content: "";
    z-index: 5;
    animation-name: arrow1;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    @include mq(sp) {
      bottom: -40px;
      background-size: 20px auto;
      width: 20px;
      height: 60px;
    }
  }

  &:after {

  }

  &__ttl {
    margin-bottom: 50px;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.4;
    color: #fff;
    letter-spacing: .2em;
    @include mq(pc) {
      letter-spacing: 0.08em;
    }
    @include mq(tab) {
      font-size: 32px;
    }
    @include mq(sp) {
      margin-bottom: 30px;
      font-size: 25px;
      letter-spacing: 0.01em;
    }

    strong {
      color: #ffff00;
    }
  }

  &__txt {
    margin-bottom: 36px;
    font-size: 18px;
    color: #fff;
    font-weight: normal;
    letter-spacing: .15px;
    @include mq(tab) {
      font-size: 16px;
    }
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 13px;
    }
  }

  &__sub-ttl {
    margin-top: 60px;
    font-size: 34px;
    font-weight: bold;
    color: #fff;
    letter-spacing: .15em;
    @include mq(tab) {
      font-size: 20px;
    }
    @include mq(sp) {
      margin-top: 30px;
      font-size: 20px;
      line-height: 1.4;
    }
  }
}

.start {
  font-family: unquote($familyD);
  margin-bottom: 50px;
  padding-top: 120px;
  padding-bottom: 330px;
  text-align: center;
  background-image: url("../img/top/bg_start_01.png");
  background-size: auto 240px;
  background-position: left bottom;
  background-repeat: repeat-x;
  @include mq(tab) {
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 40px;
    background: none;
  }
  @include mq(sp) {
    padding-top: 50px;
  }

  &__ico {
    margin-bottom: 20px;
  }

  &__ico-txt {
    margin-bottom: 5px;
    display: block;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: $color-red;
    @include mq(sp) {
      font-size: 16px;
    }
  }

  &__ttl {
    margin-bottom: 20px;
    font-size: 50px;
    font-weight: bold;
    @include mq(pc) {
      font-size: 45px;
    }
    @include mq(tab) {
      font-size: 28px;
    }
    @include mq(sp) {
      font-size: 24px;
      line-height: 1.4;
    }

    strong {
      color: $color-red;
    }
  }

  &__txt {
    letter-spacing: .15em;
    color: #3e3e3e;
    font-size: 22px;
    font-weight: normal;
    @include mq(tab) {
      font-size: 16px;
    }
    @include mq(sp) {
      font-size: 13px;
    }
  }

  &__img {
    margin-top: 40px;
  }
}

/*top-section*/
.top-section {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  @include mq(tab) {
    padding: 40px 0;
  }

  &__en {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
    padding-bottom: 10px;
    letter-spacing: 0.05em;
    overflow: hidden;
    @include mq(sp) {
      margin-bottom: 30px;
      font-size: 16px;
    }

    &:before {
      position: absolute;
      bottom: 0px;
      left: 50%;
      width: 34px;
      height: 1px;
      background: #c2c2c2;
      transform: translateX(-50%);
      content: "";
    }

    &-inner {
      display: block;
    }
  }

  &__ttl {
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 34px;
    color: $color-red;
    letter-spacing: .2em;
    overflow: hidden;
    @include mq(tab) {
      margin-bottom: 30px;
      font-size: 26px;
      letter-spacing: .18em;
    }
    @include mq(sp) {
      font-size: 24px;
      line-height: 1.5;
    }

    + .top-section__sub-ttl {
      margin-top: -30px;
      @include mq(sp) {
        margin-top: -10px;
      }
    }

    &-inner {
      display: block;
    }
  }

  &__sub-ttl {
    font-size: 40px;
    font-weight: bold;
    @include mq(pc) {
      font-size: 36px;
    }
    @include mq(tab) {
      font-size: 30px;
    }
    @include mq(sp) {
      font-size: 18px;
    }

    mark {
      font-size: 64px;
      background: transparent;
      @include mq(tab) {
        font-size: 40px;
      }
      @include mq(sp) {
        font-size: 24px;
      }
    }
  }

  &__txt {
    letter-spacing: .15em;
    margin-bottom: 90px;
    color: #3e3e3e;
    font-weight: normal;
    font-size: 16px;
    @include mq(tab) {
      font-size: 16px;
    }
    @include mq(sp) {
      margin-bottom: 40px;
      text-align: left;
      font-size: 13px;
      line-height: 1.8;
    }

    strong {
      background: linear-gradient(transparent 60%, rgba(#d2d900, 0.26) 40%);
    }
  }

  &__sub-txt {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: .15em;
    @include mq(sp) {
      margin-bottom: 1em;
      font-size: 16px;
    }
  }

  &__btn {
    position: relative;
    display: block;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid #505050;
    border-radius: 5px;
    width: 340px;
    height: 60px;
    margin: 0 auto;
    line-height: 60px;
    transition: all 300ms;
    @include mq(sp) {
      width: 100%;
      font-size: 16px;
    }

    &:hover {
      text-decoration: none;
      background: #505050;
      color: #fff;

      &:before {
        right: 18px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
      }
    }

    &:before {
      position: absolute;
      top: 24px;
      right: 22px;
      width: 10px;
      height: 10px;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      transform: rotate(45deg);
      content: "";
      transition: all 300ms;
    }
  }

  &--bg {
    background: #f4f4f4;
  }

  &--support {
    margin-top: 60px;
    padding-top: 128px;
    padding-bottom: 88px;
    background-image: url("../img/top/bg_support_01.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include mq(tab) {
      padding-top: 80px;
      padding-bottom: 40px;
    }

    .top-section__en {
      color: #fff;
    }

    .top-section__ttl {
      color: #fff;
    }
  }
}

#under-triangle {
  background: url("../img/top/under-triangle.png") center no-repeat;
  @include mq(sp) {
    background: url("../img/top/under-triangle_sp.png") center no-repeat;
  }
}
#under-triangle p {
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
  @include mq(sp) {
    margin-bottom: 15px;
  }
}

.strategy-block-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @include mq(tab) {
    display: block;
  }
}

.strategy-block {
  position: relative;
  width: 48%;
  max-width: 520px;
  min-height: 400px;
  padding: 45px;
  border-radius: 6px;
  box-sizing: border-box;
  @include mq(tab) {
    width: 100%;
    height: auto;
    padding: 30px 20px;
    min-height: inherit;
    max-width: inherit;
  }

  &--01 {
    background: #bbe4d6;

    .strategy__block-sub-ttl {
      letter-spacing: 0.05em;
    }
  }

  &--02 {
    background: #fae7b8;
    @include mq(tab) {
      margin-top: 20px;
      padding: 35px 20px 30px
    }

    &:before {
      position: absolute;
      top: 50%;
      left: -70px;
      width: 97px;
      width: 0px;
      height: 34px;
      background-image: url("../img/top/ico_arrow_02.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 97px auto;
      transform: translateY(-50%);
      content: "";
      animation-name: arrow2;
      animation-duration: 2s;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;

      @include mq(tab) {
        background-image: url("../img/top/ico_arrow_01.png");
        background-size: 20px;
        width: 20px;
        height: 60px;
        top: -40px;
        left: 50%;
        transform: translate(-50%, 0);
        animation-name: arrow1;
      }
    }
  }

  &__ttl {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    background: #fff;
    color: $color-red;
    border-radius: 30px;
    margin-bottom: 40px;
    padding: 10px 58px;
    @include mq(pc) {
      white-space: nowrap;
    }
    @include mq(tab) {
      margin-bottom: 20px;
      font-size: 20px;
    }
    @include mq(sp) {
      margin-bottom: 15px;
      font-size: 16px;
    }
  }

  &__sub-ttl {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: bold;
    @include mq(pc) {
      font-size: 22px;
      margin-bottom: 1em;
    }
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
}

.feature-block-wrap {
  display: flex;
  flex-wrap: wrap;
  @include mq(sp) {
    display: block;
  }
}

.feature-block {
  position: relative;
  width: 33.3%;
  box-sizing: border-box;
  text-align: left;
  padding: 30px;
  border-top: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  @include mq(tab) {
    width: 50%;
    padding: 0;
  }
  @include mq(sp) {
    width: 100%;
    border-right: none;
  }

  &:before {
    position: absolute;
    top: 0;
    right: -1px;
    width: 1px;
    height: 60px;
    background: $color-red;
    content: "";
    @include mq(sp) {
      top: -1px;
      right: inherit;
      left: 0;
      width: 60px;
      height: 1px;
    }
  }

  &:nth-child(3n) {
    border-right: none;
    @include mq(tab) {
      border-right: 1px solid #d0d0d0;
    }
    @include mq(sp) {
      border-right: none;
    }

    &:before {
      display: none;
      @include mq(tab) {
        display: block;
      }
    }
  }

  &:nth-child(2n) {
    @include mq(tab) {
      border-right: none;
      &:before {
        display: none;
      }
    }
    @include mq(sp) {
      &:before {
        display: block;
      }
    }
  }

  &__ttl {
    font-size: 20px;
    font-weight: bold;
    @include mq(sp) {
      font-size: 16px;
      padding-top: 10px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 10px;
      margin-bottom: 0;
      &:after {
        position: absolute;
        top: 19px;
        width: 7px;
        height: 7px;
        margin-left: 1em;
        border-top: 1px solid #383b4a;
        border-right: 1px solid #383b4a;
        -ms-transform: rotate(45deg);
        transform: rotate(
            135deg
        );
        content: "";
        transition: all 300ms;
      }
    }
  }

  &__txt {
    @include mq(sp) {
      display: none;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.howto-block-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 70px;
  justify-content: space-around;
  @include mq(sp) {
    display: block;
  }
}

.howto-block {
  position: relative;
  width: 350px;
  height: 350px;
  padding: 95px 10px 50px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 5px 10px rgba(#999, .4);
  @include mq(pc) {
    max-width: 350px;
    width: 31%;
    height: auto;
    padding: 70px 20px 30px;
  }
  @include mq(sp) {
    width: 100%;
    margin: 0 auto 100px;
    padding: 40px 20px 30px;
    &:last-child {
      margin: 0 auto 20px;
    }
  }

  &:before {
    position: absolute;
    top: 50%;
    left: -45px;
    width: 116px;
    height: 58px;
    background-image: url("../img/top/ico_arrow_03.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% auto;
    transform: translateY(-50%);
    content: "";
    @include mq(pc) {
      width: 80px;
    }
    @include mq(tab) {
      left: -30px;
      width: 40px;
      margin-top: -20px;
    }
    @include mq(sp) {
      top: -115px;
      margin-top: 0;
      background-image: url("../img/top/ico_arrow_04.png");
      width: 38px;
      height: 156px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__number {
    position: absolute;
    top: -115px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 130px;
    font-weight: lighter;
    @include mq(pc) {
      top: -90px;
      font-size: 100px;
    }
    @include mq(tab) {
      top: -70px;
      font-size: 80px;
    }
    @include mq(sp) {
      top: -50px;
      font-size: 60px;
    }
  }

  &__ico {
    margin-bottom: 10px;
  }

  &__ttl {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
    @include mq(tab) {
      font-size: 18px;
      line-height: 1.4;
    }
  }

  &__txt {
    @include mq(tab) {
      font-size: 13px;
    }
  }

  &--01 {
    &:before {
      display: none;
    }

    .howto-block__number {
      color: #80cdb2;
    }
  }

  &--02 {
    .howto-block__number {
      color: #5bbddd;
    }
  }

  &--03 {
    .howto-block__number {
      color: #c495df;
    }
  }
}

.merit-block-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 140px;
  justify-content: space-between;
  @include mq(pc) {
    padding-top: 100px;
  }
  @include mq(sp) {
    display: block;
    padding-top: 80px;
  }
}

.merit-block {
  position: relative;
  width: 250px;
  height: 360px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 5px 10px rgba(#999, .4);
  @include mq(pc) {
    width: 24%;
    padding-bottom: 30px;
    height: auto;
  }
  @include mq(tab) {
    width: 48%;
    margin-bottom: 100px;
  }
  @include mq(sp) {
    width: 100%;
    margin-bottom: 80px;
    &:last-child {
      margin-bottom: 20px;
    }
  }

  &__number {
    position: absolute;
    top: -105px;
    right: 0;
    font-size: 119px;
    line-height: 1;
    font-weight: lighter;
    @include mq(pc) {
      font-size: 100px;
      top: -90px;
    }
    @include mq(tab) {
      font-size: 80px;
      top: -70px;
    }
    @include mq(sp) {
      font-size: 60px;
      top: -60px;
    }
  }

  &__ttl-wrap {
    display: table;
    width: 100%;
    height: 130px;
    margin-bottom: 35px;
    box-sizing: border-box;
    @include mq(sp) {
      height: auto;
      margin-bottom: 20px;
      padding: 20px 0;
    }
  }

  &__ttl {
    display: table-cell;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.3;
    vertical-align: middle;
    color: #fff;
    @include mq(pc) {
      font-size: 18px;
    }
  }

  &__txt {
    padding: 0 20px;
  }

  &--01 {
    .merit-block__ttl-wrap {
      background: #80cdb2;
    }

    .merit-block__number {
      color: #80cdb2;
    }

    .merit-block__txt {
      + .merit-block__txt {
        position: relative;
        margin-top: 40px;

        &:before {
          position: absolute;
          top: -30px;
          left: 50%;
          margin-left: -14px;
          width: 14px;
          height: 14px;
          border-right: 1px solid #000;
          border-bottom: 1px solid #000;
          transform: rotate(45deg);
          content: "";
        }
      }
    }
  }

  &--02 {
    .merit-block__ttl-wrap {
      background: #5bbddd;
    }

    .merit-block__number {
      color: #5bbddd;
    }
  }

  &--03 {
    .merit-block__ttl-wrap {
      background: #c495df;
    }

    .merit-block__number {
      color: #c495df;
    }
  }

  &--04 {
    .merit-block__ttl-wrap {
      background: #face76;
    }

    .merit-block__number {
      color: #face76;
    }
  }
}

.case-block {
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  background: #f4f4f4;
  margin-bottom: 30px;
  @include mq(sp) {
    display: block;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__txt-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    max-width: 540px;
    @include mq(tab) {
      width: 50%;
    }
    @include mq(sp) {
      width: 100%;
    }
  }

  &__txt-inner {
    text-align: left;
    padding: 0 95px;
    @include mq(pc) {
      padding: 30px 20px;
    }
  }

  &__name {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
    @include mq(tab) {
      font-size: 18px;
    }
  }

  &__txt {
    font-size: 15px;
    @include mq(sp) {
      font-size: 13px;
    }
  }

  &__img {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 55%;

    .bg_img {
      visibility: hidden;
    }

    @include mq(sp) {
      width: 100%;
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse
  }
}

.plan-display {
  width: 460px;
  margin: 0 auto 60px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 20px;
  @include mq(tab) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 40px;
  }

  &__ttl {
    padding: 21px 0;
    font-size: 22px;
    font-weight: bold;
    background: $color-red;
    color: #fff;
    @include mq(tab) {
      font-size: 18px;
    }
    @include mq(sp) {
      font-size: 16px;
      padding: 15px 0;
    }
  }

  &__txt {
    padding: 12px 0;
    font-size: 36px;
    font-weight: bold;
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 20px;
    @include mq(tab) {
      font-size: 30px;
    }
    @include mq(sp) {
      padding: 10px 0;
      font-size: 26px;
    }

    .tax {
      font-size: 20px;
    }
  }

  &__func {
    padding: 6px 20%;
    font-size: 20px;
    text-align: left;
    @include mq(sp) {
      padding: 6px 10%;
    }

    &::before {
      content: "✔";
      margin-right: 10px;
      color: $color-red;
    }
  }
}

.plan-block-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @include mq(tab) {
    display: block;
  }
}

.plan-block {
  width: 320px;
  @include mq(pc) {
    margin-bottom: 30px;
  }
  @include mq(tab) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 60px;
    &:last-child {
      margin: 0 auto 20px;
    }
  }

  &__ico {
    margin-bottom: 30px;
    @include mq(tab) {
      width: 100px;
      margin: 0 auto 20px;
    }
  }

  &__ttl {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    @include mq(sp) {
      margin-bottom: 1em;
      font-size: 18px;
    }
  }

  &__txt {
    text-align: left;
  }
}

.support-block-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include mq(tab) {
    display: block;
  }
}

.support-block {
  position: relative;
  width: 340px;
  height: 340px;
  background: #fff;
  border-radius: 5px;
  padding: 35px;
  box-sizing: border-box;
  @include mq(pc) {
    margin-bottom: 20px;
    width: 48%;
    height: auto;
    padding-bottom: 120px;
  }
  @include mq(tab) {
    width: 100%;
    max-width: 520px;
    margin: 0 auto 20px;
    padding: 40px;
  }
  @include mq(sp) {
    padding: 20px;
  }

  &__ttl {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 30px;
    @include mq(tab) {
      font-size: 24px;
    }
    @include mq(sp) {
      font-size: 20px;
    }
  }

  &__txt {
    text-align: left;
    @include mq(tab) {
      margin-bottom: 30px;
    }
    @include mq(sp) {
      margin-bottom: 1em;
    }
  }

  &__btn {
    display: block;
    position: absolute;
    bottom: 35px;
    left: 50%;
    width: 270px;
    height: 60px;
    line-height: 60px;
    @include fadeAnime;
    border-radius: 30px;
    font-weight: bold;
    color: #fff;
    transform: translateX(-50%);
    @include mq(tab) {
      bottom: 0;
      left: 0;
      position: relative;
      margin: 0 auto;
      transform: translateX(0);
      width: 100%;
    }

    &:before {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 10px;
      height: 10px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
      content: "";
      transition: right 300ms;
    }

    &:hover {
      text-decoration: none;

      &:before {
        right: 18px;
      }
    }
  }

  &--01 {
    .support-block__ttl {
      color: #80cdb2;
    }

    .support-block__btn {
      background-color: #80cdb2;
    }
  }

  &--02 {
    .support-block__ttl {
      color: #5bbddd;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    .support-block__btn {
      background-color: #5bbddd;
    }
  }

  &--03 {
    .support-block__ttl {
      color: #c495df;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    .support-block__btn {
      background-color: #c495df;
    }
  }
}

.bottom-section {
  padding: 80px 0;
  background: $color-red;
  text-align: center;
  @include mq(sp) {
    padding: 40px 0;
  }

  &__ttl {
    margin-bottom: 34px;
    font-size: 34px;
    font-weight: bold;
    color: #fff;
    @include mq(sp) {
      font-size: 16px;
    }
  }

  &__btn {
    display: block;
    width: 340px;
    height: 80px;
    margin: 0 auto;
    background: #fff;
    color: $color-red;
    line-height: 80px;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.05em;
    transition: all 300ms;
    @include fadeAnime;
  }

  &__form {
    &-input {
      width: 304px;
      height: 61px;
      border: 1px solid #fff;
      box-sizing: border-box;
      padding: 0 25px;
      font-size: 16px;
      vertical-align: middle;
      -webkit-appearance: none;
      border-radius: 0;
      box-shadow: none;
      margin-top: 5px;
      margin-bottom: 5px;
      @include mq(tab) {
        display: block;
        width: 100%;
        max-width: 300px;
        margin: 0 auto 10px;
        height: 50px;
        padding: 0 20px;
      }
    }

    &-btn {
      margin: 0 10px;
      background: #fff;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.05em;
      text-align: center;
      border-radius: 5px;
      color: $color-red;
      border: none;
      width: 304px;
      height: 71px;
      box-sizing: border-box;
      vertical-align: middle;
      cursor: pointer;
      @include fadeAnime;
      @include mq(tab) {
        display: block;
        font-size: 15px;
        height: 50px;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
      }
    }

    &-btn.contact {
      width: 350px;
      display: inline-block;
      font-size: 1.8rem;
      padding-top: 12px;
      padding-bottom: 12px;
      height: auto;
      font-weight: bold;
      font-family: Arial;
      transition: all 300ms;
      @include mq(tab) {
        font-size: 18px;
      }

      &:hover {
        opacity: 1;
        background: #505050;
        color: #fff;
      }
    }
  }
}

#feature {
  @include mq(sp) {
    padding: 0;
  }
}
