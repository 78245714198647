/* ------------------------------------------------
 	common  parts    
  ---------------------------------------------- */
.wrap,.fade {
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration:none;
  }
}
.ib {
  display: inline-block;
}
.phoneTxt {
  text-decoration: none;
  cursor: default;
}
.is_pc {
  display: block !important;
  @include mq(tab) {
    display: none !important;
  }
}
.is_hidden_pc {
	display: none;
	@include mq(tab) {
		display: block;
	}
}
.is_tab {
  display: none !important;
  @include mq(tab) {
    display: block !important;
  }
  @include mq(sp) {
    display: none !important;
  }
}
.is_sp {
  display: none !important;
  @include mq(sp) {
    display: block !important;
  }
}
.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  @include mq(tab) {
    width: 100%;
    box-sizing: border-box;
  }
}
.animation {
  opacity:0;
  &.animated {
    animation-fill-mode: both;
  }
}
.animation--fade-up {
  &.animated {
		animation-duration: 1s;
    animation-name: fade-up;
  }
}
.animation--fade-in {
  &.animated {
		animation-timing-function: ease-in;
		animation-duration: 500ms;
    animation-name: fade-in;
  }
}
.animation--zoom-in {
  &.animated {
		animation-timing-function: ease-in;
		animation-duration: 500ms;
    animation-name: zoom-in;
  }
}
.animation--zoom-in-2 {
  &.animated {
		animation-timing-function: ease-in;
		animation-duration: 500ms;
		animation-delay: 300ms;
    animation-name: zoom-in;
  }
}
.animation--zoom-in-3 {
  &.animated {
		animation-timing-function: ease-in;
		animation-duration: 500ms;
		animation-delay: 600ms;
    animation-name: zoom-in;
  }
}
.animation--zoom-in-4 {
  &.animated {
		animation-timing-function: ease-in;
		animation-duration: 500ms;
		animation-delay: 900ms;
    animation-name: zoom-in;
  }
}
.animation--hyokkori {
  &.animated {
		animation-timing-function: ease-in-out;
		animation-duration: 600ms;
    animation-name: hyokkori;
  }
}
.animation--fade-left {
  &.animated {
		animation-timing-function: ease-in-out;
		animation-duration: 500ms;
    animation-name: fade-left;
  }
}