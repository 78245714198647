/* ------------------------------------------------
 	common
  ---------------------------------------------- */
body {
  font-family: unquote($familyA);
  -webkit-text-size-adjust: 100%;
  color: $color;
  background: $bg;
  font-size: 16px;
  line-height: 1.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  @include mq(sp) {
    font-size: 13px;
  }
}

a {
  color: $aColor;
  @include txtLine(hLine);
  @include mq(sp) {
    color: black;
  }
}

strong {
  font-weight: bold;
}

small {
  font-size: 12px;
}

@font-face {
  font-family: 'Noto Sans CJK JP Subset';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans CJK JP'),
  local('NotoSansCJKjp-Bold'),
  local('NotoSansJP-Bold'),
  url('../font/NotoSansCJKjp-Bold.woff2') format('woff2'),
  url('../font/NotoSansCJKjp-Bold.woff') format('woff'),
  url('../font/NotoSansCJKjp-Bold.ttf') format('truetype');
}

h2 {
  font-size: 2em;
}

.font-noto {
  font-family: "Noto Sans", "Noto Sans CJK JP", "Noto Sans CJK JP Subset", sans-serif;
}

.font-roboto {
  font-family: 'Roboto Condensed', sans-serif;
}

/* wrapper
-------------------------*/
.wrapper {
  overflow: hidden;
  @include mq(tab) {
    padding-bottom: 55px;
  }

  img {
    @include rwd-img;
  }
}

/* header
-------------------------*/
.header {
  display: flex;
  align-items: center;
  height: 70px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 0 0 5px rgba(#000, .2);
  @include mq(pc) {
    display: block;
  }
  @include mq(tab) {
    height: 65px;
    min-width: auto;
  }

  &__logo {
    display: block;
    margin-left: 25px;
    width: 158px;
    @include fadeAnime;
    @include mq(pc) {
      width: 160px;
    }
    @include mq(tab) {
      margin-left: 20px;
    }
  }

  &__bar {
    @include mq(pc) {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: 90;
      box-shadow: 0 0 5px rgba(#000, .1);
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    right: 25px;
    @include mq(pc) {
      right: 80px;
    }
    @include mq(tab) {
      display: none;
    }

    &-txt {
      font-weight: bold;
      line-height: 70px;
      display: inline-block;
      @include mq(tab) {
        line-height: 65px;
      }

      a {
        border: 1px solid #505050;
        border-radius: 5px;
        padding: 10px 15px;
        font-size: 14px;
        background: #fff;

        &:hover {
          text-decoration: none;
          background-color: #505050;
          transition: all 300ms;
          color: #fff;
        }
      }

      + .header__menu-txt {
        margin-left: 4px;

        &:before {
          margin-right: 16px;
        }
      }
    }

    &-txt-2 {
      font-weight: bold;
      line-height: 70px;
      display: inline-block;
      @include mq(tab) {
        padding-top: 14px !important;
      }

      &:before {
        display: none;
      }

      a {
        border: none;
        color: #fff;
        background-color: $color-red;
        border-radius: 5px;
        padding: 10px 15px;
        font-size: 14px;

        &:hover {
          text-decoration: none;
          background-color: #505050;
          transition: all 300ms;
          color: #fff;
        }
      }

      + .header__menu-txt {
        margin-left: 4px;

        &:before {
          margin-right: 16px;
        }
      }
    }
  }

  &__menu-btn {
    display: none;
    position: absolute;
    top: 15px;
    right: 25px;
    width: 40px;
    height: 38px;
    box-sizing: border-box;

    &:before {
      position: absolute;
      top: 10px;
      left: 5px;
      box-sizing: border-box;
      width: 30px;
      height: 18px;
      border-top: 2px solid $color-red;
      border-bottom: 2px solid $color-red;
      content: "";
      transition: all 300ms;
    }

    &:after {
      position: absolute;
      top: 18px;
      left: 5px;
      content: "";
      width: 30px;
      height: 2px;
      background: $color-red;
      transition: all 300ms;
    }

    @include mq(pc) {
      display: block;
    }
    @include mq(tab) {
      top: 13px;
      right: 20px;
    }

    &.is_open {
      &:before {
        transform: rotate(45deg);
        border-bottom: none;
        top: 16px;
        left: 0px;
      }

      &:after {
        transform: rotate(-45deg);
        top: 18px;
        left: 6px;
      }
    }
  }
}

/* gnav
-------------------------*/
.gnav {
  margin-left: 20px;
  @include mq(pc) {
    position: absolute;
    margin-left: 0;
    top: -72px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(#fff, .9);
    transition: all 300ms;
    -webkit-overflow-scrolling: touch;
    z-index: 10;
  }
  @include mq(tab) {
    top: -260px;
    box-shadow: 0 0 5px rgba(#000, .2);
  }

  &__list {
    text-align: center;
    @include clearfix;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    @include mq(pc) {
      justify-content: center;
    }
    @include mq(tab) {
      display: block;
      padding-bottom: 14px;
    }
  }

  &__item {
    position: relative;
    line-height: 70px;
    padding: 0 10px;
    @media screen and (max-width: 1176px) {
      padding: 0 8px;
    }
    @include mq(tab) {
      line-height: 1.5;
      padding: 0;
      border-bottom: 1px solid #ddd;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 18px;
      transform: translateY(-50%);
      background: $color;
      content: "";
      @include mq(tab) {
        display: none;
      }
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &--contact {
      margin-right: 5px;
      @media screen and (max-width: 1176px) {
        margin-right: 0;
        padding: 0 0 0 10px;
      }
    }

    @include mq(pc) {
      &--contact {
        position: fixed;
        bottom: 0;
        right: 0;
        display: block;
        background: #f4f4f4;
        line-height: 40px;
        @include mq(tab) {
          width: 100%;
          display:none;
        }

        .gnav__link {
          position: relative;
          line-height: 1;
          padding: 15px 40px 15px 30px;
          transition: all 300ms;
          @include mq(tab) {
            padding: 20px 40px 20px 30px;
          }

          &:hover {
            text-decoration: none;
            background: #505050;
            color: #fff;

            &:before {
              right: 15px;
              border-top: 1px solid #fff;
              border-right: 1px solid #fff;
              @include mq(tab) {
                right: inherit;

              }
            }
          }

          .gg-chevron-right-o {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            transform: scale(var(--ggs, 1));
            width: 22px;
            height: 22px;
            border: 2px solid $color-red;
            border-radius: 100px;
            top: 5px;
            left: 5px;
          }

          .gg-chevron-right-o::after {
            content: "";
            display: block;
            box-sizing: border-box;
            position: absolute;
            width: 6px;
            height: 6px;
            border-bottom: 2px solid $color-red;
            border-right: 2px solid $color-red;
            transform: rotate(-45deg);
            left: 5px;
            top: 6px
          }

          //&:before {
          //position: absolute;
          //top: 18px;
          //right: 20px;
          //width: 7px;
          //height: 7px;
          //border-top: 1px solid $color;
          //border-right: 1px solid $color;
          //-ms-transform: rotate(45deg);
          //transform: rotate(45deg);
          //content: "";
          //transition: right 300ms;
          //transition: all 300ms;
          //@include mq(tab) {
          //  right: inherit;
          //  top: 23px;
          //  left: 50%;
          //  margin-left: 3.5em;
          //}
          //}

        }
      }
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    padding: 0 10px;
    color: $color;
    transition: all 300ms;

    &:after {
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 0;
      height: 3px;
      border-radius: 10px;
      background: $color-red;
      content: "";
      transition: all 300ms;
      @include mq(tab) {
        display: none;
      }
    }

    &:hover {
      text-decoration: none;

      &:after {
        width: 100%;
      }
    }

    @media screen and (max-width: 1176px) {
      padding: 0 2px;
    }
    @include mq(pc) {
      padding: 0 10px;
    }
    @include mq(tab) {
      display: block;
      padding: 14px 0;
      font-size: 15px;
    }
  }

  &__menu {
    display: none;
    @include mq(tab) {
      background: #fff;
      color: $color-red;
      width: calc(100% - 40px);
      max-width: 300px;
      margin: 0 auto;
      border-radius: 2px;
      padding: 10px 0;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
    }
  }

  &__sp-wrap {
    @include mq(tab) {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }

  &.is_open {
    top: 70px;
    @include mq(tab) {
      top: 65px;
    }
  }
}

/* breadcrumb
-------------------------*/
.breadcrumb {
  margin-bottom: 60px !important;
  padding: 15px 0;
  @include mq(tab) {
    margin-top: -30px;
    margin-bottom: 20px;
    padding: 0;
  }

  &__item {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;

    + .breadcrumb__item {
      position: relative;
      margin-left: 10px;
      padding-left: 20px;
      background-image: url("../img/common/ico_breadcrumb_01.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 4.5px auto;
    }

    @include mq(sp) {
      font-size: 12px;
    }
  }
}

/* contents
-------------------------*/
.contents {
  margin-top: 20px;
  @include mq(tab) {
    margin-top: 50px;
  }
}

/* footer
-------------------------*/
.footer {
  padding: 80px 0 0;
  text-align: center;
  @include mq(sp) {
    padding: 40px 0 0;
  }

  &__sns {
    margin-bottom: 10px;

    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;
    }

    a {
      @include fadeAnime;
    }
  }

  &__logo {
    display: inline-block;
    margin-bottom: 60px;
    @include fadeAnime;
    @include mq(sp) {
      margin-bottom: 40px;
    }
  }

  &__bottom {
    position: relative;
    padding: 40px 0;
    background: #f4f4f4;
    @include mq(sp) {
      padding: 0;
      background: #fff;
      .container {
        padding: 0;
      }
    }

    &-btn {
      position: absolute;
      top: 24px;
      right: 58px;
      display: block;
      font-size: 18px;
      font-weight: bold;
      border: 1px solid #505050;
      border-radius: 5px;
      width: 240px;
      height: 60px;
      margin: 0 auto;
      line-height: 60px;
      transition: all 300ms;
      @include mq(pc) {
        position: relative;
        top: 0;
        right: 0;
        margin: 20px auto 0;
        width: 100%;
        max-width: 300px;
      }
      @include mq(sp) {
        width: calc(100% - 40px);
        max-width: inherit;
        margin-bottom: 10px;
        font-size: 16px;
      }

      &:hover {
        text-decoration: none;
        background: #505050;
        color: #fff;

        &:before {
          right: 18px;
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
        }
      }

      &:before {
        position: absolute;
        top: 24px;
        right: 22px;
        width: 10px;
        height: 10px;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        transform: rotate(45deg);
        content: "";
        transition: all 300ms;
      }
    }
  }

  &__link {
    @include mq(sp) {
      border-top: 1px solid #ddd;
    }

    li {
      display: inline-block;
      font-size: 12px;
      margin: 0 12px;
      @include mq(sp) {
        display: block;
        font-size: 15px;
        margin: 0;
        a {
          display: block;
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }

  &__copyright {
    text-align: center;
    padding: 20px 0;
    @include mq(sp) {
      font-size: 11px;
      padding: 0 20px 20px;
    }
  }
}

.page-top {
  position: relative;
  background: #9c9c9c;
  padding: 30px 0 10px;
  color: #fff;
  font-size: 11px;
  letter-spacing: 0.1em;

  &:before {
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -8px;
    width: 12px;
    height: 12px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    content: "";
    transform: rotate(45deg);
  }
}
